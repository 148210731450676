import {useEffect, useState} from 'react';

interface UseImageLoaderResult {
  resolvedSrc?: string;
  isLoaded: boolean;
  showFallback: boolean;
}

export const useImageLoader = (src: string | Promise<string>): UseImageLoaderResult => {
  const [resolvedSrc, setResolvedSrc] = useState<string | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFallback, setShowFallback] = useState(true);

  useEffect(() => {
    Promise.resolve(src)
    .then((url) => setResolvedSrc(url))
    .catch(() => {
      setResolvedSrc(undefined);
      setIsLoaded(true);
      setShowFallback(true);
    });
  }, [src]);

  useEffect(() => {
    if (resolvedSrc) {
      const img = new Image();
      img.src = resolvedSrc;
      img
      .decode()
      .then(() => {
        setShowFallback(false);
        setIsLoaded(true);
      })
      .catch(() => {
        setShowFallback(true);
        setIsLoaded(true);
      });
    }
  }, [resolvedSrc]);

  return { resolvedSrc, isLoaded, showFallback };
};
