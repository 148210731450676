import {useState, useCallback} from 'react';

type UseGalleryStateProps = {
  initialSlide: number;
};

type UseGalleryStateReturn = {
  open: boolean;
  clickedSlide: number;
  setOpen: (isOpen: boolean) => void;
  openGallery: (event: React.MouseEvent<HTMLButtonElement>, imageIndex: number) => void;
};

export const useGalleryState = ({initialSlide}: UseGalleryStateProps): UseGalleryStateReturn => {
  const [open, setOpen] = useState<boolean>(false);
  const [clickedSlide, setClickedSlide] = useState<number>(initialSlide);

  const openGallery = useCallback((event: React.MouseEvent<HTMLButtonElement>, imageIndex: number): void => {
    event.preventDefault();
    setOpen(true);
    setClickedSlide(imageIndex);
  }, []);

  return {
    open,
    clickedSlide,
    setOpen,
    openGallery,
  };
};
