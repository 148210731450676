import React, {FormEvent, useCallback} from 'react';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import Spinner from '../../atoms/Spinner';
import Notification from '../../molecules/Notification';
import UploadArea from './UploadArea';
import {FeedbackFormProps} from './types';
import {useImageHandlers} from './utils';

const FeedBackForm = (props: FeedbackFormProps) => {
  const {placeholder, onSubmit, onChange, isLoading, isSuccess, successMsg, value, maxUploads = 4, ...divAttr} = props;
  const {images, onChangeFile, resetImages} = useImageHandlers();

  const onSubmitForm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit({images, resetImages});
    },
    [images, onSubmit],
  );

  return (
    <div {...divAttr} className="FeedbackForm">
      {isSuccess && <Notification mode="embed">{successMsg}</Notification>}
      {!isSuccess && (
        <div className="relative">
          <form className="flex flex-col items-end gap-2" onSubmit={onSubmitForm}>
            <Input
              name="feedback"
              onChange={(event) => onChange(event)}
              placeholder={placeholder}
              isMultiline
              multilineRows={5}
              isDisabled={isLoading}
              value={value}
            />
            {isLoading && images.length > 0 && (
              <div className="flex justify-center items-center gap-1 pb-6 w-full">
                <Spinner active={true} color="blue" borderWidth={0.1} size={16} />
                <p className="paragraph-3">Uploading data...</p>
              </div>
            )}
            <div className="flex sm:flex-row flex-col items-center gap-2 w-full">
              <UploadArea onChange={onChangeFile} maxUploads={maxUploads} imagesList={images} />
              <Button className="min-w-full sm:min-w-40" flavor="primary" size="medium" disabled={isLoading}>
                {isLoading ? <Spinner active={true} borderWidth={0.1} size={20} /> : 'Submit'}
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default FeedBackForm;
