import React, { useMemo } from 'react';

import classNames from 'classnames';
import { ToolbarProps } from './types';

import './index.scss';
import '../../atoms/ToolButton/index.scss';

/**
 * This components represents UI of toolbar.
 * Should be using with ToolButtons and ToolbarGroups inside.
 * Can be horizontal or vertical.
 */
const Toolbar = (props: ToolbarProps) => {
  const { children, className, orientation = 'vertical', ...divAttr } = props;

  const wrapperClasses = useMemo(() => classNames(
    'toolbar-wrapper',
    orientation,
    className
  ), [className, orientation]);

  return <div { ...divAttr} className={wrapperClasses}>{children}</div>;
};

export default Toolbar;