import React from 'react';
import { UpcomingFeaturesProps } from './types';
import UpcomingFeaturesHandler from './UpcomingFeaturesHandler';

/**
 * Component that adds an `upcoming-features` get param to current url if it is exists in prev url
 */
const UpcomingFeatures = (props: UpcomingFeaturesProps) => {
  return <UpcomingFeaturesHandler {...props} />;
};

export default UpcomingFeatures;