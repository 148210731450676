import React from "react";
import Badge from "../../atoms/Badge";
import { HeaderProps } from "./types";

const renderBadges = (content?: string | string[]) => {
  if (!content) return null;
  if (Array.isArray(content)) {
    return <div>
      {content.map((badge, index) => (
        <span key={index} className="relative bottom-0.5 mr-1">
          <Badge value={badge} flavor="base" shape="square" />
        </span>
      ))}
    </div>
  }
  return (
    <span className="relative bottom-0.5 mr-1">
      <Badge value={content} flavor="base" shape="square" />
    </span>
  );
};

const QuoteLineHeader: React.FC<HeaderProps> = ({ badgeContent, title, price }) => (
  <div className="flex items-end w-full">
    <div className="flex items-start gap-1">
      <p className="inline-block font-semibold text-base leading-snug">
        {renderBadges(badgeContent)}
        {title}
      </p>
    </div>
    {price && (
      <>
        <div className="flex-grow border-gray-400 mx-3 mb-2.5 border-b border-dashed" />
        <p className="mt-0.5 font-semibold text-base leading-snug">{price}</p>
      </>
    )}
  </div>
);

export default QuoteLineHeader;
