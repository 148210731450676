import React, {useEffect} from 'react';

interface UsePreventWheelEventProps {
  type?: string;
  containerRef: React.RefObject<HTMLDivElement>;
}

const usePreventWheelEvent = (props:UsePreventWheelEventProps) => {
  const {
    type,
    containerRef
  } = props;
  useEffect(() => {
    if (type && ['number', 'amount'].includes(type) && containerRef.current) {
      const wheelHandler = (e: WheelEvent) => {
        e.preventDefault()
        e.stopPropagation()
      }
      containerRef.current.addEventListener('wheel', wheelHandler, { passive: false })
      return () => containerRef.current?.removeEventListener('wheel', wheelHandler)
    }
  }, [type])
}

export default usePreventWheelEvent
