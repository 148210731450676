import React from 'react';

import Checkbox from '../../atoms/Checkbox';
import { CheckboxGroupProps } from './types';

import './index.scss';

const CheckboxGroup = (props: CheckboxGroupProps & { 'data-qa'?: string}) => {
  const { items, onSelect, onSelectAll, selectPlaceholder = 'Select All', ...divProps } = props;
  const isAllSelected = items.every(item =>item.checked);

  const dataQa = divProps['data-qa'];

  return (
    <div { ...divProps } className="CheckboxGroup">
      <div className='CheckboxGroup-items'>
        <div className='CheckboxGroup-title'>{selectPlaceholder}</div>
        <Checkbox
          onClick={onSelectAll}
          checked={isAllSelected}
          data-qa={dataQa ? `${dataQa}-select-all` : undefined}>
          <br />
        </Checkbox>
      </div>
      {items.map(({checked, value}, index) => (
        <div key={index} className='CheckboxGroup-items'>
          <span className='CheckboxGroup-title'>{value}</span>
          <Checkbox onClick={(e) => onSelect(e, index)} checked={checked} data-qa={dataQa ? `${dataQa}-${index}` : undefined}>
            <br />
          </Checkbox>
        </div>
      ))}
    </div>
  );
}

export default CheckboxGroup;