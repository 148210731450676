import {useState} from 'react';
import {ImageListType} from 'react-images-uploading';

export const useImageHandlers = () => {
  const [images, setImages] = useState<ImageListType>([]);

  const onChangeFile = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const resetImages = () => {
    setImages([]);
  };

  return {
    images,
    onChangeFile,
    resetImages,
  };
};
