// useInputHandlers.tsx
import React, {useCallback, useState} from 'react'
import {InputTypes, InputTypesStatic, OnChangeType, OnFocusType} from '../types'
import {cleanValueFromDollarSign, controlNumberRange, formatAmount} from '../utils'

type UseInputHandlersProps = {
  type?: InputTypes | InputTypesStatic
  min?: number
  max?: number
  onChange?: (event: OnChangeType, name?: string, value?: string) => void
  onFocus?: (event: OnFocusType) => void
  onBlur?: (event: OnFocusType) => void
  onKeyUp?: (e: React.KeyboardEvent, name?: string, value?: string) => void
  maxLength?: number
  name?: string
  value?: string
}

const useInputHandlers = ({
  type,
  min,
  max,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  maxLength,
  name,
}: UseInputHandlersProps) => {
  const [isFocus, setIsFocus] = useState(false)

  const getCheckedValue = useCallback((localValue?: string) => {
      if (type === InputTypes.Number) {
        return controlNumberRange(localValue, min, max).toString()
      }
      if (type === InputTypes.Amount) {
        return formatAmount(localValue?.toString() || '')
      }
      return localValue?.toString()
    },
    [type, min, max]
  )

  const handleChange = useCallback(
    (event: OnChangeType): void => {
      const { value: eventValue } = event.target
      if (maxLength && eventValue.length > maxLength) return
      if (onChange) {
        const finalValue = type === InputTypes.Amount ? cleanValueFromDollarSign(eventValue) : eventValue
        onChange(event, name, getCheckedValue(finalValue))
      }
    },
    [name, getCheckedValue, onChange, maxLength, type]
  )
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent): void => {
      if (onKeyUp) onKeyUp(event, name, event.key)
    },
    [name, onKeyUp]
  )
  const handleBlur = useCallback(
    (event: OnFocusType): void => {
      setIsFocus(false)
      if (onBlur) {
        if (type === InputTypes.Amount) {
          event.target.value = formatAmount(event.target.value)
        }
        onBlur(event)
      }
    },
    [onBlur, type]
  )
  const handleFocus = useCallback(
    (event: OnFocusType): void => {
      setIsFocus(true)
      if (onFocus) {
        if (type === InputTypes.Amount) {
          event.target.value = cleanValueFromDollarSign(event.target.value)
        }
        onFocus(event)
      }
    },
    [onFocus, type]
  )
  return {
    isFocus,
    getCheckedValue,
    handleChange,
    handleKeyUp,
    handleBlur,
    handleFocus,
  }
}

export default useInputHandlers
