import React, { useEffect, useRef, useState } from 'react';
import { DropdownMenuProps } from './types';
import './index.scss';

const DropdownMenu = (props: DropdownMenuProps) => {
  const {
    children,
    isActive,
    position,
    toggle,
    disabled,
    onAction = () => {},
    className = '',
    activeTrigger = ['click'],
    preventClose = false,
    useTriggerWidth = false,
    toggleClassName,
    positionStrategy = 'fixed',
    'data-qa': dataQa,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const positionMethod = positionStrategy === 'fixed' ? 'fixed' : 'absolute';

  useEffect(() => {

    // Only add click handler if it doesn't exist and if we listen to 'click'
    if (
      typeof window !== 'undefined' &&
      isOpen &&
      activeTrigger.includes('click') &&
      !preventClose
    ) {
      // global click handler
      const clickOutside = (e: MouseEvent) => {
        if (
          menuRef.current &&
          !menuRef.current.contains(e.target as Node) &&
          buttonRef.current &&
          !buttonRef.current.contains(e.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener('click', clickOutside);

      return () => {
        document.removeEventListener('click', clickOutside);
      };
    }
  }, [isOpen, activeTrigger, preventClose]);

  const toggleDropdown = () => {
    if (disabled) return;

    if (!isOpen) {
      setIsOpen(true);
    } else {
      if (!preventClose) {
        setIsOpen(false);
      }
    }
  };

  let menuStyles: Partial<React.CSSProperties> = {};
  const positionCalc = () => {
    const menuNode = menuRef.current;
    const buttonNode = buttonRef.current;

    if (!buttonNode || !menuNode) {
      return {
        visibility: 'hidden' as const,
      };
    }

    if (useTriggerWidth) {
      menuStyles = {
        width: buttonNode.clientWidth,
      };
    }

    const buttonRect = buttonNode.getBoundingClientRect();

    const globalPosition = buttonRect;

    return {
      top: position?.indexOf('bottom') !== -1 ? globalPosition.bottom + 4 : 'auto',
      right: position?.indexOf('left') !== -1 ? 0 : 'auto',
      bottom: position?.indexOf('top') !== -1 ? 'auto' : 'auto',
      left: position?.indexOf('right') !== -1 ? 'auto' : globalPosition.left,
    };
  };

  const triggerIsActive = isActive !== undefined ? isActive : isOpen;

  return (
    <div
      className={`DropdownMenu ${!isOpen ? 'closed' : ''} ${className}`}
      data-qa={dataQa}
    >
      <div
        ref={buttonRef}
        className={`DropdownMenu-trigger ${triggerIsActive ? 'is-active' : ''} ${
          toggleClassName || ''
        }`}
        onClick={activeTrigger.includes('click') ? toggleDropdown : undefined}
        onMouseEnter={activeTrigger.includes('hover') ? toggleDropdown : undefined}
        onMouseLeave={
          activeTrigger.includes('hover') && !preventClose ? () => setIsOpen(false) : undefined
        }
      >
        {toggle}
      </div>

      {isOpen && (
        <div
          className={`DropdownMenu-content ${position || 'menu-right'}`}
          ref={menuRef}
          style={{
            position: positionMethod,
            ...(positionMethod === 'fixed' ? positionCalc() : {}),
            ...menuStyles,
          }}
          onClick={() => onAction()}
          onMouseLeave={
            activeTrigger.includes('hover') && !preventClose ? () => setIsOpen(false) : undefined
          }
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;