// InputUI.tsx
import React, { FC, RefObject } from 'react';
import InputLabel from './InputLabel';
import ValidationMessage from './ValidationMessage';

interface InputUIProps {
  containerClasses: string;
  containerRef: RefObject<HTMLDivElement>;
  inputComponent?: JSX.Element;
  rightIcon?: JSX.Element;
  name?: string;
  label?: string;
  isValid?: boolean;
  hasValue?: boolean;
  validationMessage?: string | null;
}

const InputUI: FC<InputUIProps> = ({
  containerClasses,
  containerRef,
  inputComponent,
  rightIcon,
  name,
  label,
  isValid,
  hasValue,
  validationMessage,
}) => {

  const validationMsg = (
    <ValidationMessage
      validationMessage={validationMessage}
      isValid={isValid}
    />
  )

  return (
    <div className={containerClasses} ref={containerRef}>
      {inputComponent}
      {rightIcon}
      <InputLabel name={name} label={label} isValid={isValid} hasValue={hasValue} />
      {validationMsg}
    </div>
  );
};

export default InputUI;
