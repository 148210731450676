import React, { HTMLAttributes, useMemo } from "react";
import SurveyNumberItems from "./SurveyNumberItems";
import { getRangeOfNumbers } from "./utils";
import { SurveyNumbersRangeProps } from "./types";

import './index.scss';

const SurveyNumbersRange = (props: SurveyNumbersRangeProps & HTMLAttributes<HTMLDivElement>) => {
  const { min, max, intervals, onClick, title, value, ...divAttr } = props;

  const range = useMemo(() => {
    return getRangeOfNumbers(min, max, intervals);
  }, [min, max, intervals]);

  return (
    <div { ...divAttr} className={`SurveyNumbersRange ${divAttr.className || ''}`}>
      {range.map((number, indx) => (
        <SurveyNumberItems
          key={`${Date.now()}-${indx}`}
          position={number}
          onClick={onClick}
          title={title}
          value={value}
        />
      ))}
    </div>
  );
};

export default SurveyNumbersRange;