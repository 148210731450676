import React, {forwardRef, HTMLAttributes, ReactElement, ReactNode} from 'react';
import classNames from 'classnames';

import Button from '../../../atoms/Button';
import type {ButtonProps} from '../../../atoms/Button/types';
import Link from '../../../atoms/ErgeonLink';
import type {ErgeonLinkProps} from '../../../atoms/ErgeonLink';
import {Flavor} from '../abstracts';

import './TopPanelNavItem.scss';

interface TopPanelNavItemWrapperProps<Node extends HTMLElement> extends HTMLAttributes<Node> {
  children?: ReactNode;
  component: React.FC | string;
  isFixed?: boolean;
}

const getTopPanelNavItemWrapper = <P, Node extends HTMLElement>() =>
  forwardRef<Node, TopPanelNavItemWrapperProps<Node> & P>((props, ref) => {
    const {component: Component, isFixed, ...otherProps} = props;

    return <Component {...otherProps} ref={ref} />;
  });

const TopPanelNavItemWrapper = getTopPanelNavItemWrapper();

export const isItemFixed = (child: ReactElement) => child.props.isFixed;

type TopPanelNavItemProps<T extends HTMLElement> = Omit<TopPanelNavItemWrapperProps<T>, 'component'>;

export const TopPanelNavItem = (props: TopPanelNavItemProps<HTMLDivElement>) => (
  <TopPanelNavItemWrapper {...props} component="div" />
);

interface TopPanelNavItemButtonProps extends Omit<ErgeonLinkProps, 'onClick'>, TopPanelNavItemProps<HTMLElement> {
  flavor?: Flavor;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const TopPanelNavItemButton = forwardRef<HTMLButtonElement, TopPanelNavItemButtonProps>((props, ref) => {
  const {className, flavor, ...otherProps} = props;

  return (
    <TopPanelNavItemWrapper
      {...otherProps}
      component="button"
      className={classNames('top-panel__link', {[`is-${flavor}`]: Boolean(flavor)}, className)}
      ref={ref}
    />
  );
});

interface TopPanelNavItemLinkProps extends TopPanelNavItemButtonProps {}

export const TopPanelNavItemLink = (props: TopPanelNavItemLinkProps) => {
  const {className, flavor, ...otherProps} = props;

  return (
    <TopPanelNavItemWrapper
      {...otherProps}
      component={Link}
      className={classNames('top-panel__link', {[`is-${flavor}`]: Boolean(flavor)}, className)}
    />
  );
};

type TopPanelNavItemCTAProps = ButtonProps & TopPanelNavItemProps<HTMLButtonElement>;

export const TopPanelNavItemCTA = (props: TopPanelNavItemCTAProps) => (
  <TopPanelNavItemWrapper {...props} component={Button} />
);

export const isItemCTA = (child: ReactElement) => child.type === TopPanelNavItemCTA;
