import {useMemo} from 'react';
import {ListItem} from '../../DropdownList/types';

interface UseListTransformerProps {
  items: ListItem[];
  onItemClick?: () => void;
}

export const useListTransformer = ({items, onItemClick}: UseListTransformerProps) => {
  const transformedList = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        onClick: () => {
          item.onClick?.();
          onItemClick?.();
        },
      })),
    [items, onItemClick],
  );

  return transformedList;
};
