(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@ergeon/erg-utils-js"), require("@googlemaps/js-api-loader"), require("@react-spring/web"), require("classnames"), require("lodash"), require("lodash/find"), require("lodash/first"), require("lodash/isEmpty"), require("lodash/isNil"), require("lodash/kebabCase"), require("lodash/last"), require("lodash/noop"), require("lodash/omit"), require("lodash/range"), require("lodash/sortBy"), require("lodash/throttle"), require("lodash/times"), require("lodash/toPairs"), require("lodash/union"), require("lodash/uniqueId"), require("lodash/zipObject"), require("lodash/zipWith"), require("moment"), require("prop-types"), require("react"), require("react-datepicker"), require("react-helmet"), require("react-images-uploading"), require("react-input-mask-format"), require("react-measure"), require("react-portal"), require("react-pose"), require("react-qr-code"), require("react-router-dom"), require("react-select"), require("react-signature-canvas"), require("react-svg"), require("react-transition-group"), require("requestanimationframe-timer"), require("ssr-window"), require("swiper"), require("swiper/react"), require("uuid"));
	else if(typeof define === 'function' && define.amd)
		define("@ergeon/core-components", ["@ergeon/erg-utils-js", "@googlemaps/js-api-loader", "@react-spring/web", "classnames", "lodash", "lodash/find", "lodash/first", "lodash/isEmpty", "lodash/isNil", "lodash/kebabCase", "lodash/last", "lodash/noop", "lodash/omit", "lodash/range", "lodash/sortBy", "lodash/throttle", "lodash/times", "lodash/toPairs", "lodash/union", "lodash/uniqueId", "lodash/zipObject", "lodash/zipWith", "moment", "prop-types", "react", "react-datepicker", "react-helmet", "react-images-uploading", "react-input-mask-format", "react-measure", "react-portal", "react-pose", "react-qr-code", "react-router-dom", "react-select", "react-signature-canvas", "react-svg", "react-transition-group", "requestanimationframe-timer", "ssr-window", "swiper", "swiper/react", "uuid"], factory);
	else if(typeof exports === 'object')
		exports["@ergeon/core-components"] = factory(require("@ergeon/erg-utils-js"), require("@googlemaps/js-api-loader"), require("@react-spring/web"), require("classnames"), require("lodash"), require("lodash/find"), require("lodash/first"), require("lodash/isEmpty"), require("lodash/isNil"), require("lodash/kebabCase"), require("lodash/last"), require("lodash/noop"), require("lodash/omit"), require("lodash/range"), require("lodash/sortBy"), require("lodash/throttle"), require("lodash/times"), require("lodash/toPairs"), require("lodash/union"), require("lodash/uniqueId"), require("lodash/zipObject"), require("lodash/zipWith"), require("moment"), require("prop-types"), require("react"), require("react-datepicker"), require("react-helmet"), require("react-images-uploading"), require("react-input-mask-format"), require("react-measure"), require("react-portal"), require("react-pose"), require("react-qr-code"), require("react-router-dom"), require("react-select"), require("react-signature-canvas"), require("react-svg"), require("react-transition-group"), require("requestanimationframe-timer"), require("ssr-window"), require("swiper"), require("swiper/react"), require("uuid"));
	else
		root["@ergeon/core-components"] = factory(root["@ergeon/erg-utils-js"], root["@googlemaps/js-api-loader"], root["@react-spring/web"], root["classnames"], root["lodash"], root["lodash/find"], root["lodash/first"], root["lodash/isEmpty"], root["lodash/isNil"], root["lodash/kebabCase"], root["lodash/last"], root["lodash/noop"], root["lodash/omit"], root["lodash/range"], root["lodash/sortBy"], root["lodash/throttle"], root["lodash/times"], root["lodash/toPairs"], root["lodash/union"], root["lodash/uniqueId"], root["lodash/zipObject"], root["lodash/zipWith"], root["moment"], root["prop-types"], root["react"], root["react-datepicker"], root["react-helmet"], root["react-images-uploading"], root["react-input-mask-format"], root["react-measure"], root["react-portal"], root["react-pose"], root["react-qr-code"], root["react-router-dom"], root["react-select"], root["react-signature-canvas"], root["react-svg"], root["react-transition-group"], root["requestanimationframe-timer"], root["ssr-window"], root["swiper"], root["swiper/react"], root["uuid"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__5643__, __WEBPACK_EXTERNAL_MODULE__7589__, __WEBPACK_EXTERNAL_MODULE__3490__, __WEBPACK_EXTERNAL_MODULE__992__, __WEBPACK_EXTERNAL_MODULE__6773__, __WEBPACK_EXTERNAL_MODULE__465__, __WEBPACK_EXTERNAL_MODULE__380__, __WEBPACK_EXTERNAL_MODULE__4035__, __WEBPACK_EXTERNAL_MODULE__3741__, __WEBPACK_EXTERNAL_MODULE__4903__, __WEBPACK_EXTERNAL_MODULE__7058__, __WEBPACK_EXTERNAL_MODULE__6978__, __WEBPACK_EXTERNAL_MODULE__3895__, __WEBPACK_EXTERNAL_MODULE__4767__, __WEBPACK_EXTERNAL_MODULE__3987__, __WEBPACK_EXTERNAL_MODULE__2694__, __WEBPACK_EXTERNAL_MODULE__8076__, __WEBPACK_EXTERNAL_MODULE__9008__, __WEBPACK_EXTERNAL_MODULE__2765__, __WEBPACK_EXTERNAL_MODULE__6056__, __WEBPACK_EXTERNAL_MODULE__318__, __WEBPACK_EXTERNAL_MODULE__7293__, __WEBPACK_EXTERNAL_MODULE__5416__, __WEBPACK_EXTERNAL_MODULE__1949__, __WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__2824__, __WEBPACK_EXTERNAL_MODULE__4883__, __WEBPACK_EXTERNAL_MODULE__6730__, __WEBPACK_EXTERNAL_MODULE__2083__, __WEBPACK_EXTERNAL_MODULE__5958__, __WEBPACK_EXTERNAL_MODULE__760__, __WEBPACK_EXTERNAL_MODULE__9203__, __WEBPACK_EXTERNAL_MODULE__2817__, __WEBPACK_EXTERNAL_MODULE__4442__, __WEBPACK_EXTERNAL_MODULE__3110__, __WEBPACK_EXTERNAL_MODULE__6231__, __WEBPACK_EXTERNAL_MODULE__2992__, __WEBPACK_EXTERNAL_MODULE__4971__, __WEBPACK_EXTERNAL_MODULE__406__, __WEBPACK_EXTERNAL_MODULE__335__, __WEBPACK_EXTERNAL_MODULE__1408__, __WEBPACK_EXTERNAL_MODULE__4136__, __WEBPACK_EXTERNAL_MODULE__7315__) {
return 