import React, {useRef} from 'react';

import classNames from 'classnames';

import './Slider.scss';

import {SliderProps} from './types';

const Slider = ({min, max, step = 1, value, onChange, className, reverse = false}: SliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const containerClasses = classNames('slider', className);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const handleMouseMove = (e: MouseEvent) => {
      updateValue(e.clientX);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    updateValue(event.clientX);
  };

  const updateValue = (clientX: number) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      let percentage = (clientX - rect.left) / rect.width;

      if (reverse) {
        percentage = 1 - percentage;
      }

      const newValue = Math.round((percentage * (max - min) + min) / step) * step;
      const clampedValue = Math.min(Math.max(newValue, min), max);
      onChange(clampedValue);
    }
  };

  const getProgressPercentage = () => {
    const progress = ((value - min) / (max - min)) * 100;

    return reverse ? 100 - progress : progress;
  };

  return (
    <div className={containerClasses} onMouseDown={handleMouseDown} ref={sliderRef} role="slider">
      <div className="slider__track">
        <div className="slider__progress" style={{width: `${getProgressPercentage()}%`}} />
      </div>
      <div className="slider__thumb" style={{left: `${getProgressPercentage()}%`}}>
        <div className="slider__thumb-lines">
          <div className="slider__thumb-line" />
          <div className="slider__thumb-line" />
          <div className="slider__thumb-line" />
        </div>
      </div>
    </div>
  );
};

export default Slider;
