import React from 'react';
import {StepBarProps} from './types';
import Step from './Step';
import {map} from 'lodash';
import getStepStatus from './utils/getStepStatus';

export const StepBar: React.FC<StepBarProps> = ({
  steps = ['Step1', 'Step2', 'Step3', 'Step4', 'Step 5'],
  currentStep = 3,
}) => {
  return (
    <div className="flex justify-start items-start gap-2 p-px w-fit max-w-full overflow-x-auto">
      {map(steps, (step, index) => (
        <Step key={index} title={step} status={getStepStatus(index, currentStep)} />
      ))}
    </div>
  );
};

export default StepBar;
