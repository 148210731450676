// Fallback component if no images are available
import {CameraIcon} from '@ergeon/icons/hero/solid';
import React from 'react';

const ImageFallback: React.FC = () => (
  <div className="flex justify-center items-center w-full h-full aspect-square">
    <CameraIcon className="fill-gray-400 m-auto size-8 md:size-10" />
  </div>
);

export default ImageFallback;
