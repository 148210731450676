import {RefObject, useEffect, useState, useCallback} from 'react';
import {getGoogleLoader} from '../../../../libs/integrations/google';
import {parsePlace} from '../../../../libs/utils/places';
import {AutoCompleteTypes, AutoCompletePlaceData} from '../types';

/**
 * Enables Google Places Autocomplete for any given input element
 * @param {RefObject<HTMLInputElement>} inputRef
 */
export const useGooglePlacesAutocomplete = (
  inputRef: RefObject<HTMLInputElement>,
  autoCompleteType: AutoCompleteTypes = AutoCompleteTypes.GEOCODE,
) => {
  const [placeData, setPlaceData] = useState<AutoCompletePlaceData | null>(null);

  const initAutocomplete = useCallback(
    (google: typeof globalThis.google, input: HTMLInputElement) => {
      // Create the autocomplete object, restricting the search to geographical
      // location types.
      const autocomplete = new google.maps.places.Autocomplete(input, {
        types: [autoCompleteType],
        componentRestrictions: {country: 'us'},
      });

      // When the user selects an address from the dropdown, populate the address
      // fields in the form.
      autocomplete.addListener('place_changed', () => placeChanged(autocomplete));
    },
    [autoCompleteType],
  );

  const placeChanged = useCallback((autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace();
    if (place) {
      const parsedPlaceData = parsePlace(place) as unknown as AutoCompletePlaceData;
      setPlaceData(parsedPlaceData);
    }
  }, []);

  useEffect(() => {
    getGoogleLoader()
      .load()
      .then((google) => {
        if (!inputRef.current) {
          // The input is probably become unmounted while the component was loading, do nothing.
          return;
        }
        initAutocomplete(google, inputRef.current);
      });
  }, [inputRef]);

  return {placeData};
};
