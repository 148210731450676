import React from 'react';
import classNames from 'classnames';
import {ReactSVG} from 'react-svg';
import defaultIcon from '@ergeon/icons/svg/icon-success.svg';
import Tooltip from '../Tooltip';
import './index.scss';

import {ToolButtonProps} from './types';

const ToolButton: React.FC<ToolButtonProps> = (props) => {
  const {checked, className, disabled, hint, hintPosition = 'right', icon = defaultIcon, onClick} = props;

  const classes = classNames('tool-button', {
    checked,
    disabled,
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  const renderButton = () => {
    return (
      <button className={classes} onClick={handleClick} disabled={disabled}>
        <ReactSVG className="tool-button--icon" src={icon as string} />
      </button>
    );
  };

  const renderWithTooltip = () => {
    return (
      <Tooltip disabled={disabled} msg={hint} position={hintPosition}>
        {renderButton()}
      </Tooltip>
    );
  };

  const wrapperClasses = classNames({
    'tool-button--wrapper': true,
    [className as string]: className,
  });

  return <div className={wrapperClasses}>{hint ? renderWithTooltip() : renderButton()}</div>;
};

export default ToolButton;
