import isNil from 'lodash/isNil';
import {v4 as uuidv4} from 'uuid';

export const controlNumberRange = (v?: string, min?: number, max?: number): number | string => {
  if (!v || (v.length === 0)) return '';
  let value = Number(v);

  if (!isNil(min) && value < min) return min;
  if (!isNil(max) && value > max) return max;

  return value;
};

/**
 * Generates a unique identifier that will be assigned to element's `id`, `name`, and `htmlFor` attributes
 * @param prefix
 * @returns generated unique id
 */
export const getUniqueId = (prefix = '') => `${prefix}-${uuidv4()}`;

export const cleanValueFromDollarSign = (value: string): string => {
  return value.replace(/[$\s]/g, '').replace(/[^\d.]/g, '');
}

export const formatAmount = (value: string): string => {
  const cleanValue = cleanValueFromDollarSign(value);

  const parts = cleanValue.split('.');

  if (parts.length > 2) {
    parts.splice(2);
  }

  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2);
  }

  const formattedValue = parts.join('.');

  return formattedValue ? `$ ${formattedValue}` : '';
};
