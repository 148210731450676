import React, {useCallback} from 'react';
import { DialogProps } from './types';
import UniversalDialog from '../../organisms/UniversalDialog';
import { DialogButtons, DialogContent, DialogHeader } from '../../organisms/UniversalDialog';
import Button from '../../atoms/Button';

/**
 * This component represents a general Dialog window
 * Should be used when need some input or confirmation from user.
 */
const Dialog = (props: DialogProps) => {
  const {
    actionButtonFlavor,
    active: isVisible = false,
    cancelBtnName = 'Cancel',
    cancelDisabled = false,
    children,
    okBtnName = 'Ok',
    okDisabled = false,
    onCancel,
    onClose,
    onOk,
    showButtons = true,
    title,
    ...divProps
  } = props;

  const onDismiss = useCallback(() => {
    onClose ? onClose() : onCancel?.();
  }, [onCancel, onClose]);

  return (
    <UniversalDialog {...divProps} isVisible={isVisible}>
      <DialogHeader {...{title, onClose: onDismiss}} />
      <DialogContent>{children}</DialogContent>
      {showButtons && (
        <DialogButtons>
          {Boolean(onCancel) && !cancelDisabled && (
            <Button className="min-width__is-120" flavor="regular" onClick={onCancel}>
              {cancelBtnName}
            </Button>
          )}
          <Button className="min-width__is-120" flavor={actionButtonFlavor} onClick={onOk} disabled={okDisabled}>
            {okBtnName}
          </Button>
        </DialogButtons>
      )}
    </UniversalDialog>
  );
};

export default Dialog;