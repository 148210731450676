import {KeyboardEvent, useCallback, useState} from 'react';

import {TagOption, UseTagsSelectorProps} from '../types';

const useTagsSelector = (props: UseTagsSelectorProps) => {
  const {value, onChange, options, inputRef, searchOnly} = props;

  const [searchText, setSearchText] = useState('');
  const [focusedTagIndex, setFocusedTagIndex] = useState<number | null>(null);
  const [isDropdownShown, setIsDropdownShown] = useState(false);

  // Filter out options that are already selected
  const availableOptions = options.filter((option) => !value.some((selectedTag) => selectedTag.value === option.value));

  // Filter options based on search text
  const filteredOptions =
    searchText.trim() === ''
      ? availableOptions
      : availableOptions.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

  // Check if the search text matches any existing option
  const isNewTag =
    searchText.trim() !== '' && !options.some((option) => option.label.toLowerCase() === searchText.toLowerCase());

  // Show dropdown and focus input
  const showDropdown = () => {
    setIsDropdownShown(true);
  };

  // Add new tag to the list
  const handleAddTag = (tag: TagOption) => {
    if (!value.some((selectedTag) => selectedTag.value === tag.value)) {
      onChange([...value, tag]);
    }
    setSearchText('');
    inputRef.current?.focus();
  };

  // Create a new tag from search text - only if not in searchOnly mode
  const handleCreateNewTag = () => {
    if (searchText.trim() !== '' && !searchOnly) {
      const newTag: TagOption = {
        value: searchText.trim(),
        label: searchText.trim(),
        removable: true,
      };
      handleAddTag(newTag);
    }
  };

  // Remove tag from the list
  const handleRemoveTag = (tagToRemove: TagOption) => {
    if (!tagToRemove.removable) return;
    onChange(value.filter((tag) => tag.value !== tagToRemove.value));
    setFocusedTagIndex(null);
    inputRef.current?.focus();
  };

  // Clear all selected tags
  const handleClearAll = () => {
    onChange([]);
    setSearchText('');
    inputRef.current?.focus();
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Handle keyboard navigation for tags
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    // Arrow key navigation only when dropdown is shown and search text is empty
    if (isDropdownShown && searchText === '' && value.length > 0) {
      if (e.key === 'ArrowLeft') {
        const newIndex = focusedTagIndex === null ? value.length - 1 : Math.max(0, focusedTagIndex - 1);
        setFocusedTagIndex(newIndex);
      } else if (e.key === 'ArrowRight' && focusedTagIndex !== null) {
        const newIndex = focusedTagIndex + 1 < value.length ? focusedTagIndex + 1 : null;
        setFocusedTagIndex(newIndex);
      } else {
        setFocusedTagIndex(null);
      }
    }

    // If Backspace is pressed with empty input, remove the last tag
    if (e.key === 'Backspace' && searchText === '' && value.length > 0) {
      if (focusedTagIndex !== null) {
        handleRemoveTag(value[focusedTagIndex]);
      } else {
        handleRemoveTag(value[value.length - 1]);
      }
    }

    // If Delete is pressed and a tag is focused, remove that tag
    if (e.key === 'Delete' && focusedTagIndex !== null) {
      handleRemoveTag(value[focusedTagIndex]);
    }

    // If Enter is pressed and we have search text, create a new tag (only if not in searchOnly mode)
    if (e.key === 'Enter' && searchText.trim() !== '') {
      e.preventDefault();
      if (!searchOnly) {
        handleCreateNewTag();
      } else if (filteredOptions.length > 0) {
        // In search-only mode, select the first filtered option instead of creating a new tag
        handleAddTag(filteredOptions[0]);
      }
    }

    // If Escape is pressed, close dropdown
    if (e.key === 'Escape') {
      setIsDropdownShown(false);
      inputRef.current?.blur();
    }
  };

  // Handle click outside to close dropdown
  const handleClickOutside = useCallback(() => {
    setIsDropdownShown(false);
    setFocusedTagIndex(null);
  }, []);

  // Prepare dropdown list items
  const dropdownItems = [
    ...filteredOptions.map((option) => ({
      label: option.label,
      onClick: () => handleAddTag(option),
    })),
    ...(isNewTag && !searchOnly
      ? [
          {
            label: `${searchText} (New Tag)`,
            onClick: handleCreateNewTag,
          },
        ]
      : []),
  ];

  return {
    searchText,
    isDropdownShown,
    focusedTagIndex,
    dropdownItems,
    showDropdown,
    handleRemoveTag,
    handleClearAll,
    handleInputChange,
    handleKeyDown,
    handleClickOutside,
  };
};

export default useTagsSelector;
