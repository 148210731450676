import React, {useEffect, useRef} from 'react';

import classNames from 'classnames';
import {XMarkIcon, XCircleIcon, ChevronDownIcon} from '@ergeon/icons/hero/mini';

import DropdownList from '../../molecules/DropdownList';
import Button from '../../atoms/Button';
import {useClickOutside} from '../../utils/useClickOutside';

import {TagsSelectorProps} from './types';
import useTagsSelector from './hook/useTagsSelector';

const TagsSelector = (props: TagsSelectorProps) => {
  const {onChange, options, value, placeholder, searchOnly = false} = props;
  const defaultPlaceholder = searchOnly ? 'Search by tag' : 'No tags';
  const finalPlaceholder = placeholder || defaultPlaceholder;

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    searchText,
    isDropdownShown,
    focusedTagIndex,
    dropdownItems,
    showDropdown,
    handleRemoveTag,
    handleClearAll,
    handleInputChange,
    handleKeyDown,
    handleClickOutside,
  } = useTagsSelector({value, onChange, options, inputRef, searchOnly});

  useClickOutside(containerRef, handleClickOutside);

  // Focus input when dropdown is shown
  useEffect(() => {
    if (isDropdownShown && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDropdownShown]);

  const isEmpty = value.length === 0;

  const containerClasses = classNames(
    'flex justify-between items-center min-h-9 text-sm leading-5 rounded cursor-pointer transition-all duration-200 border',
    {
      // Ring only when dropdown is shown
      'border-gray-500 ring-4 ring-gray-300': isDropdownShown,
      // For search-only mode when dropdown is not shown
      'border-gray-300 bg-gray-100 hover:bg-white': searchOnly && !isDropdownShown,
      // For regular mode when dropdown is not shown
      'border-transparent hover:bg-gray-200': !searchOnly && !isDropdownShown,
    },
  );

  return (
    <div className="relative" onKeyDown={handleKeyDown} ref={containerRef}>
      <div className={containerClasses} onClick={showDropdown}>
        <div className="flex flex-wrap items-center gap-2.5 py-2 px-2.5 w-full overflow-hidden">
          {isEmpty && !isDropdownShown && !searchText ? (
            <span className="text-gray-600">{finalPlaceholder}</span>
          ) : (
            <>
              <div className="flex flex-wrap w-full gap-2.5">
                {value.map((tag, index) => (
                  <Button
                    key={tag.value}
                    className={focusedTagIndex === index ? '!bg-gray-200 !text-gray-700' : ''}
                    flavor="regular"
                    onClick={showDropdown}
                    size="small"
                    taste="line"
                  >
                    {tag.label}
                    {isDropdownShown && tag.removable && (
                      <Button
                        onClick={() => handleRemoveTag(tag)}
                        size="small"
                        isSquare
                        taste="boundless"
                        flavor="regular"
                      >
                        <XMarkIcon className="size-4 text-gray-600" />
                      </Button>
                    )}
                  </Button>
                ))}
                {isDropdownShown && (
                  <input
                    className="flex-1 bg-transparent border-0 text-sm p-0 focus:outline-none"
                    ref={inputRef}
                    type="text"
                    value={searchText}
                    onChange={handleInputChange}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {isDropdownShown && (
          <div className="flex justify-center items-center min-h-9 min-w-9">
            {searchOnly ? (
              <XCircleIcon onClick={() => handleClearAll()} className="size-4 text-gray-600 hover:text-gray-700" />
            ) : (
              <ChevronDownIcon className="size-4 text-gray-600" />
            )}
          </div>
        )}
      </div>
      {isDropdownShown && (
        <div className="mt-3 absolute z-10 w-full">
          <DropdownList
            className="max-h-72 overflow-y-auto card shadow__z2"
            list={dropdownItems.length ? dropdownItems : [{label: 'No tags found', disabled: true}]}
          />
        </div>
      )}
    </div>
  );
};

export default TagsSelector;
