import {ChevronLeftIcon, ChevronRightIcon} from '@ergeon/icons/hero/micro';
import React, {SetStateAction} from 'react';
import Button from '../../atoms/Button';

interface NavigationControlsProps {
  imagesLength: number;
  setActiveIndex: React.Dispatch<SetStateAction<number>>;
}

const NavigationControls: React.FC<NavigationControlsProps> = ({imagesLength, setActiveIndex}) => {
  const onNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % imagesLength);
  };

  const onPrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + imagesLength) % imagesLength);
  };
  const navBtnClasses = `top-1/2 z-10 absolute
    opacity-0 group-hover:opacity-100
    transition-all duration-300 ease-in-out
    hover:scale-110 active:scale-95`;
  return (
    <>
      <Button
        taste="line"
        flavor="regular"
        size="small"
        isSquare
        className={navBtnClasses + ' left-2'}
        onClick={onPrev}
      >
        <ChevronLeftIcon className="size-4" />
      </Button>
      <Button
        taste="line"
        flavor="regular"
        size="small"
        isSquare
        className={navBtnClasses + ' right-2'}
        onClick={onNext}
      >
        <ChevronRightIcon className="size-4" />
      </Button>
    </>
  );
}

export default NavigationControls;
