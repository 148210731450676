import React, {useMemo} from 'react';
import {CameraIcon} from '@ergeon/icons/hero/outline';
import Thumbnails from './Thumbnails';
import ImageContainer from '../../atoms/ImageContainer';
import Title from '../../atoms/Title';
import EmptyState from '../../molecules/EmptyState';
import FullGalleryDialog from '../../molecules/FullGalleryDialog';
import {AnimatedPopup} from '../../atoms/PopUp';
import Button from '../../atoms/Button';
import {XMarkIcon} from '@ergeon/icons/hero/micro';
import {ImageGalleryProps} from './types';
import {useGalleryState, useImagesArray} from './hooks';

const ImageGallery = (props: ImageGalleryProps) => {
  const {images = [], initialSlide = 0, title, titleIcon, thumbnailNumDisplay = 1} = props;

  const {open, clickedSlide, setOpen, openGallery} = useGalleryState({initialSlide});

  const imagesArray = useImagesArray(images);

  const isThumbnailsView = useMemo(() => thumbnailNumDisplay > 1, [thumbnailNumDisplay]);

  const GalleryCloseButton: React.FC<{onClose: () => void}> = ({onClose}) => (
    <div className="top-0 right-5 absolute">
      <Button onClick={onClose} flavor="regular" taste="boundless" className="text-white/50 hover:text-white">
        Close [Esc]
        <XMarkIcon className="size-4" />
      </Button>
    </div>
  );

  if (images.length === 0) {
    return <EmptyState title="No images available" icon={<CameraIcon />} />;
  }

  const renderThumbnails = () => {
    if (isThumbnailsView) {
      return (
        <div className="flex flex-row flex-wrap gap-2 w-full">
          <Thumbnails images={images} maxThumbnails={thumbnailNumDisplay} onClick={openGallery} />
        </div>
      );
    }

    return (
      <button className="size-24" onClick={(e) => openGallery(e, initialSlide)}>
        <ImageContainer
          hasHover
          aspect="square"
          fit="cover"
          altText={images[initialSlide].title || 'Thumbnail'}
          src={images[initialSlide].url}
        />
      </button>
    );
  };

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        {title && (
          <Title icon={titleIcon}>
            {title} ({images.length})
          </Title>
        )}
        {renderThumbnails()}
      </div>
      <AnimatedPopup isVisible={open} onChangeVisible={(visible) => setOpen(visible)}>
        <GalleryCloseButton onClose={() => setOpen(false)} />
        <FullGalleryDialog images={imagesArray} initialIndex={clickedSlide} />
      </AnimatedPopup>
    </>
  );
};

export default ImageGallery;
