import React, {forwardRef, useCallback} from 'react';
import {Portal} from 'react-portal';
import classNames from 'classnames';

import {DialogClose} from '../../organisms/UniversalDialog';

import getRootNode from './getRootNode';
import useBlockBodyScroll from './useBlockBodyScroll';

import useEscapeKey from './useEscapeKey';
import {PopupProps} from './types';

const PopUp = forwardRef<HTMLDivElement, PopupProps>((props, ref) => {
  const {
    children,
    className,
    isCloseIconShown,
    isVisible,
    onChangeVisible,
    rootNode = getRootNode() as HTMLElement,
    ...divProps
  } = props;

  const {id} = useBlockBodyScroll(isVisible, {rootNode});

  const onClose = useCallback(() => {
    onChangeVisible?.(false);
  }, [onChangeVisible]);

  useEscapeKey({onClose});

  if (!isVisible) {
    return null;
  }

  return (
    <Portal node={document.body}>
      <div
        ref={ref}
        data-testid="popup-container"
        {...divProps}
        className={classNames(
          'popup--container absolute flex items-center content-center min-h-screen w-full z-[1030]',
          {hidden: !isVisible},
          className,
        )}
        id={id}
      >
        <div className="z-[1] fixed inset-0 bg-black/80 backdrop-blur-sm popup--tint" onClick={onClose} />
        <div className="z-[2] relative mx-auto w-full sm:w-full max-w-[1180px] h-auto popup--wrapper">
          {isCloseIconShown && (
            <DialogClose
              className="top-5 right-5 z-[1030] absolute flex justify-center items-center rounded leading-none cursor-pointer pointer-events-auto popup--cross-icon"
              onClose={onClose}
            />
          )}
          <div className="z-[1030] flex justify-center items-center w-full h-auto pointer-events-auto popup--content">
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default PopUp;
