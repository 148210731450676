import classNames from 'classnames';
import {useMemo} from 'react';
import {InputSize, InputSizesStatic} from '../types';

interface UseInputClassesProps {
  sizeClass?: InputSize | InputSizesStatic;
  className?: string;
  isDisabled?: boolean;
  hasLabel?: boolean;
  isValid?: boolean;
  hasValue?: boolean;
  isFocus?: boolean;
}

const useInputClasses = ({
  sizeClass,
  isDisabled,
  hasLabel,
  isValid,
  className,
  hasValue,
  isFocus,
}: UseInputClassesProps) => {

  const containerClasses = useMemo(() => {
    return classNames({
        'erg-input': true,
        [`${sizeClass}`]: Boolean(sizeClass),
        'disabled': isDisabled,
        'no-label': !hasLabel,
        'erg-input--dirty': hasValue || isFocus,
        'erg-input--valid': isValid,
        'erg-input--invalid': isValid === false,
      },
      className
    );
  }, [sizeClass, isDisabled, hasLabel, isValid, className, hasValue, isFocus]);

  return {
    containerClasses,
  }
}

export default useInputClasses;
