import {RefObject, useEffect} from 'react';

interface UseOutsideClickProps {
  refs: RefObject<HTMLElement>[];
  isActive: boolean;
  onOutsideClick: () => void;
}

export const useOutsideClick = ({refs, isActive, onOutsideClick}: UseOutsideClickProps) => {
  useEffect(() => {
    const eventListener = (event: MouseEvent) => {
      const clickedInsideRefs = refs.some((ref) => ref.current?.contains(event.target as Node));

      if (!clickedInsideRefs) {
        onOutsideClick();
      }
    };

    if (isActive) {
      window.addEventListener('click', eventListener);
    } else {
      window.removeEventListener('click', eventListener);
    }

    return () => window.removeEventListener('click', eventListener);
  }, [refs, isActive, onOutsideClick]);
};
