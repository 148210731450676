import { getPhoneFormatter } from '../../components/utils/usePhoneFormatter';
export { isChristmasTime } from '@ergeon/erg-utils-js';

export const findComponentByTypes = (place, types) => {
  for (let i = 0; i < place.address_components.length; i++) {
    const component = place.address_components[i];
    for (let j = 0; j < types.length; j++) {
      if (component.types.indexOf(types[j]) !== -1) {
        return component;
      }
    }
  }
  return null;
};

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

export const formatPhoneNumber = (phone) => getPhoneFormatter(phone);