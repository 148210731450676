import React from 'react';
import {ImageGalleryImage} from './types';
import ImageContainer from '../../atoms/ImageContainer';

interface ThumbnailsProps {
  images: ImageGalleryImage[];
  maxThumbnails: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
}

const Thumbnails = (props: ThumbnailsProps) => {
  const {images, onClick, maxThumbnails} = props;
  const maxThumbnailsArray = images.slice(0, maxThumbnails);
  return (
    <>
      {maxThumbnailsArray.map((elem, i) => {
        return (
          <button
            className="rounded size-24 transition-all duration-300 ease-in-out cursor-pointer shrink-0"
            key={`thumbnail-${i}`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onClick(event, i)}
          >
            <ImageContainer aspect="square" fit="cover" hasHover altText={elem.title || 'Thumbnail'} src={elem.url} />
          </button>
        );
      })}
    </>
  );
};

export default Thumbnails;
