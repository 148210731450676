import React from 'react';
import {InputProps, InputSize} from './types';

export const defaultProperties: InputProps = {
  className: '',
  isDisabled: false,
  label: '',
  rightIcon: <span />,
  size: InputSize.Medium,
  validationMessage: '',
  value: '',
}
