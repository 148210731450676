import React from 'react';
import ImageUploading from 'react-images-uploading';
import Button from '../../atoms/Button';
import {UploadAreaType} from './types';
import {XMarkIcon} from '@ergeon/icons/hero/micro';
import {PaperClipIcon} from '@ergeon/icons/hero/micro';
import {VALID_EXT_TYPES, MAX_FILE_SIZE} from './utils';

const UploadArea = (props: UploadAreaType) => {
  const {onChange, maxUploads, imagesList} = props;
  return (
    <div className="w-full">
      <ImageUploading
        multiple
        value={imagesList}
        onChange={onChange}
        maxNumber={maxUploads}
        acceptType={VALID_EXT_TYPES}
        maxFileSize={MAX_FILE_SIZE}
      >
        {({imageList, onImageUpload, onImageRemove, dragProps, errors}) => (
          <div {...dragProps} className="flex sm:flex-row flex-col justify-between gap-3 w-full">
            <div className="flex gap-2">
              {imageList.map((image, index) => (
                <div key={`preview${index}`} className="relative flex-shrink-0">
                  <img className="border border-gray-300 rounded size-9" src={image.dataURL} alt="Attached Image" />
                  <button
                    className="-top-1 -right-1 absolute bg-white border border-gray-300 rounded-xl"
                    onClick={() => onImageRemove(index)}
                  >
                    <XMarkIcon className="size-3 text-gray-500" />
                  </button>
                </div>
              ))}
              {errors && (
                <div className="flex items-center gap-1 pl-2 text-orange-500 paragraph-3">
                  <XMarkIcon className="flex-shrink-0 bg-orange-500 rounded-xl size-3 text-white" />
                  <span className="line-clamp-1">
                    {errors.maxNumber && `Number of selected images exceed: ${maxUploads}`}
                    {errors.acceptType && 'Your selected file type is not allowed'}
                    {errors.maxFileSize && 'Selected file size exceed 10MB'}
                  </span>
                </div>
              )}
            </div>
            <Button
              className="flex-shrink-0"
              flavor="regular"
              size="medium"
              taste="line"
              type="button"
              onClick={onImageUpload}
              disabled={imageList.length >= maxUploads}
              {...dragProps}
            >
              <PaperClipIcon className="size-4" /> Attach Image
            </Button>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default UploadArea;
