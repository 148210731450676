import React from 'react';
import InputMask from 'react-input-mask-format';
import {InputTypes, InputTypesStatic } from '../types';

interface MaskInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  mask?: string | (string | RegExp)[];
  className?: string;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  value?: string;
  type?: InputTypes | InputTypesStatic;
}

const MaskInput = (props: MaskInputProps): JSX.Element => {
  const {
    name = '',
    type = InputTypes.Text,
    placeholder = '',
    mask = '',
  } = props;

  return (
    <InputMask
      {...props}
      className='erg-input--text'
      placeholder={placeholder}
      name={name}
      id={name}
      mask={mask}
      type={type}
      />
  )
}

export default MaskInput;
