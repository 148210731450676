import React, {HTMLAttributes, ReactNode} from 'react';
import {ReactSVG} from 'react-svg';
import ErgAuthService, {User} from '@ergeon/erg-auth';

import IconLock from '@ergeon/icons/svg/icon-lock.svg';

import Button from '../../atoms/Button/index';
import _AuthProvider from './AuthProvider';

import './index.scss';

export const AuthProvider = _AuthProvider;

type AuthProps = {
  authService: ErgAuthService;
  children(user: User): ReactNode;
  title?: string;
  btnTitle?: string;
  note?: string;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Displays our authentication UI, if auth was successful (we have the user) we render the children
 * with our user data inside
 */
function Auth(props: AuthProps) {
  const {
    authService,
    children,
    title = 'Login to Ergeon',
    btnTitle = 'Login using Ergeon ID',
    note = 'You will be redirected to the login page',
    ...divProps
  } = props;

  return (
    <AuthProvider authService={authService}>
      {({user, onAuth}) => {
        if (user) {
          return children(user);
        }
        return (
          <div {...divProps} className="soft-border Auth card padding-60">
            <ReactSVG className="Auth-icon" src={IconLock} />
            <h1 className="Auth-title spacing before__is-12 after__is-30">{title}</h1>
            <Button onClick={onAuth} size="large">
              {btnTitle}
            </Button>
            <i className="Auth-note spacing before__is-12">{note}</i>
          </div>
        );
      }}
    </AuthProvider>
  );
}

export default Auth;
