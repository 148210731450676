import React, {ReactElement, isValidElement} from 'react';
import {ReactSVG} from 'react-svg';

import {IconType} from '../types';

export const renderIcon = (icon: IconType): ReactElement | null => {
  if (!icon) return null;

  if (isValidElement(icon)) {
    return React.cloneElement(icon, {
      className: `DropdownButton-icon ${(icon.props as any).className || ''}`,
    } as React.HTMLAttributes<HTMLElement>);
  }

  if (typeof icon === 'string') {
    return <ReactSVG className="DropdownButton-icon" src={icon} />;
  }

  return null;
};
