import {useMemo} from 'react';
import {ImageGalleryImage} from '../types';

type TransformedImage = {
  description: string;
  src: string;
  alt: string;
  renderItem?: React.ReactNode;
};

export const useImagesArray = (images?: ImageGalleryImage[]): TransformedImage[] => {
  return useMemo(
    () =>
      images?.map((elem) => ({
        description: elem.title,
        src: elem.url,
        alt: elem.title || '',
        renderItem: elem.renderItem,
      })) || [],
    [images],
  );
};
