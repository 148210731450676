import {useCallback, useRef, useState} from 'react';
import {ListItem} from '../../DropdownList/types';
import {useOutsideClick} from './useOutsideClick';
import {useListTransformer} from './useListTransformer';
import {useDropdownClasses} from './useDropdownClasses';

export const useDropdownButton = (items: ListItem[], onShowDropdown?: () => void, onDropdownDismiss?: () => void) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const triggerBtnRef = useRef<HTMLButtonElement>(null);
  const [isDropdownShown, setShowDropdown] = useState<boolean>(false);

  const handleOutsideClick = useCallback(() => {
    setShowDropdown(false);
    onDropdownDismiss?.();
  }, [onDropdownDismiss]);

  useOutsideClick({
    refs: [dropdownRef, triggerBtnRef],
    isActive: isDropdownShown,
    onOutsideClick: handleOutsideClick,
  });

  const handleItemClick = useCallback(() => setShowDropdown(false), []);

  const list = useListTransformer({
    items,
    onItemClick: handleItemClick,
  });

  const triggerDropdown = useCallback(() => {
    setShowDropdown(!isDropdownShown);
    onShowDropdown?.();
  }, [isDropdownShown, onShowDropdown]);

  const dropdownListClasses = useDropdownClasses({isDropdownShown});

  return {
    dropdownRef,
    triggerBtnRef,
    isDropdownShown,
    list,
    triggerDropdown,
    dropdownListClasses,
  };
};
