import React, {useEffect, useState} from 'react';

const useImageContainerHeight = ({imageContainerRef}: {imageContainerRef: React.RefObject<HTMLDivElement> }) => {
  const [imageContainerHeight, setImageContainerHeight] = useState<number | null>(null);

  useEffect(() => {
    if (!imageContainerRef.current) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setImageContainerHeight(entry.contentRect.height);
      }
    });
    resizeObserver.observe(imageContainerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return imageContainerHeight;
}

export default useImageContainerHeight;
