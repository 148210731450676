import omit from 'lodash/omit';
import React, { forwardRef } from 'react';
import InputText from './InputText';
import MaskInput from './MaskInput';
import TextArea from './TextArea';
import { InputHandlers, InputProps } from '../types';

const OMIT_PROPS = [
  'size',
  'handleOnFocus',
  'handleOnBlur',
  'handleKeyUp',
  'handleInputChange',
  'validationMessage',
  'rightIcon',
  'isDisabled',
  'isMultiline',
  'multilineRows',
  'multilineCols',
];

const InputFactory = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps & InputHandlers>((props, ref) => {
  const {
    mask,
    isMultiline,
    isDisabled,
    handleOnFocus,
    handleOnBlur,
    handleKeyUp,
    handleInputChange,
    type,
    multilineRows,
    multilineCols,
    ...rest
  } = props;

  const commonProps = {
    ...omit(rest, OMIT_PROPS),
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    onChange: handleInputChange,
    onKeyUp: handleKeyUp,
    disabled: isDisabled,
  };

  if (mask) {
    return (
      // @ts-ignore
      <MaskInput
        {...commonProps}
        mask={mask}
      />
    );
  }

  if (isMultiline) {
    return (
      // @ts-ignore
      <TextArea
        {...commonProps}
        ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
        rows={multilineRows}
        cols={multilineCols}
      />
    );
  }

  return (
    // @ts-ignore
    <InputText
      {...commonProps}
      ref={ref as React.ForwardedRef<HTMLInputElement>}
      type={type}
    />
  );
});

export default InputFactory;
