import React, {Children, ComponentPropsWithRef, HTMLAttributes, ReactElement} from 'react';
import RadioGroupContext from './RadioGroupContext';

interface RadioGroupAttrs extends ComponentPropsWithRef<'div'> {
  name?: string;
  selectedValue?: unknown;
  onChange?: (value: unknown) => void;
  children?: React.ReactNode;
}

type RadioGroupProps = RadioGroupAttrs & HTMLAttributes<HTMLDivElement>;

const RadioGroup = (props: RadioGroupProps) => {
  const {className, name, selectedValue, onChange, children, ...rest} = props;

  return (
    <RadioGroupContext.Provider value={{name, onChange, selectedValue}}>
      <div className={`${className || ''} w-full radio-group`} role="radiogroup" {...rest}>
        <ul className="my-6 p-0 list-none">
          {Children.map(children, (child, idx) => (
            <li key={(child as ReactElement)?.key ?? idx} className="mb-8">
              {child}
            </li>
          ))}
        </ul>
      </div>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
