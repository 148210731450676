import React, {useCallback, useMemo, FormEvent} from 'react';
import classNames from 'classnames';

import Button from '../../atoms/Button/Button';
import Spinner from '../../atoms/Spinner';
import Input from '../../atoms/Input';
import useMailto from './useMailto';
import { ShareToEmailProps } from './types';

import './index.scss';

const ShareToEmail = (props: ShareToEmailProps) => {
  const {
    emailRegex,
    errorMessage,
    isError,
    onError,
    onChange,
    isLoading,
    className,
    onSubmit,
    value,
    placeholder,
    btnTitle,
  } = props;

  const validateEmail = useCallback(
    (email: string) => {
      if (emailRegex) {
        return emailRegex.test(email);
      }
      return true;
    },
    [emailRegex],
  );

  const triggerMailto = useMailto(value);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!validateEmail(value)) {
        onError?.(errorMessage);
        return;
      }

      if (onSubmit) {
        onSubmit(event);
      } else {
        triggerMailto();
      }
    },
    [onSubmit, value, validateEmail, onError, errorMessage, triggerMailto],
  );

  const containerClassName = useMemo(() => classNames('ShareToEmail-form', className), [className]);

  return (
    <form className={containerClassName} onSubmit={handleSubmit}>
      <Input value={value} placeholder={placeholder} onChange={onChange} aria-invalid={isError} />
      <Button flavor="regular" size="medium" type="submit" disabled={isLoading}>
        {isLoading ? <Spinner active={true} borderWidth={0.1} size={25} /> : btnTitle}
      </Button>
    </form>
  );
};

export default ShareToEmail;