import React from 'react';
import {StepProps, StepStatus} from './types';
import {CheckCircleIcon} from '@ergeon/icons/hero/micro';
import Tooltip from '../../atoms/Tooltip';

export const Step: React.FC<StepProps> = ({title, status}) => {
  const statusLabel = {
    [StepStatus.pending]: 'bg-gray-300',
    [StepStatus.completed]: 'bg-blue-500',
    [StepStatus.current]: 'bg-blue-200 outline-blue-200 outline-1 outline',
  };

  const statusText = {
    [StepStatus.pending]: 'text-gray-500',
    [StepStatus.completed]: 'text-gray-800',
    [StepStatus.current]: 'text-gray-900',
  };

  return (
    <div className="relative flex flex-col items-center gap-1 min-w-24 max-w-40">
      <div className={`flex w-full h-1 transition rounded-md ${statusLabel[status]}`}></div>
      <Tooltip msg={title} position="bottom">
        <div className="flex items-center gap-1 px-4">
          {status === StepStatus.completed ? <CheckCircleIcon className="size-3 text-blue-200 shrink-0" /> : ''}
          <span className={`paragraph-3 line-clamp-1 font-semibold ${statusText[status]}`}>{title}</span>
        </div>
      </Tooltip>
    </div>
  );
};

export default Step;
