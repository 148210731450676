import React, {HTMLAttributes} from 'react';
import DropdownList from '../DropdownList';
import {DropdownButtonInterface} from './types';
import {ChevronDownIcon} from '@ergeon/icons/hero/micro';
import Button from '../../atoms/Button';
import {useDropdownButton} from './hooks/useDropdownButton';
import {renderIcon} from './hooks/renderIcon';

const DropdownButton = (props: DropdownButtonInterface & HTMLAttributes<HTMLDivElement>) => {
  const {
    items,
    className = '',
    label,
    isSquare,
    icon,
    flavor = 'regular',
    taste = 'boundless',
    size = 'medium',
    onShowDropdown,
    onDropdownDismiss,
    children,
    ...divAttr
  } = props;

  const {dropdownRef, triggerBtnRef, isDropdownShown, list, triggerDropdown, dropdownListClasses} = useDropdownButton(
    items,
    onShowDropdown,
    onDropdownDismiss,
  );

  return (
    <div {...divAttr} className={className}>
      <div className="inline-block DropdownButton-wrapper">
        <Button
          isSquare={isSquare}
          size={size}
          taste={taste}
          flavor={flavor}
          className="DropdownButton"
          ref={triggerBtnRef}
          onClick={triggerDropdown}
        >
          {icon && renderIcon(icon)}
          {label}
          <ChevronDownIcon className="size-4 DropdownButton-arrow" />
        </Button>
      </div>
      {isDropdownShown && (
        <div ref={dropdownRef} className={`DropdownButton-dropdown z-10 ${dropdownListClasses}`}>
          <DropdownList className="relative shadow-lg" list={list} />
        </div>
      )}
      {children}
    </div>
  );
};

export default DropdownButton;
