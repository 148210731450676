export type StepProps = {
  title: string;
  status: StepStatus;
};

export enum StepStatus {
  pending,
  completed,
  current,
}

export interface StepBarProps {
  steps?: string[];
  currentStep?: number;
}
