import React from 'react';
import {ArchiveBoxXMarkIcon} from '@ergeon/icons/hero/outline';
import {EmptyStateProps} from './types';

const EmptyState: React.FC<EmptyStateProps> = ({
  icon = <ArchiveBoxXMarkIcon />,
  title = 'Nothing to show',
  description = 'We have nothing to show here at the moment',
  actions,
}) => {
  const iconWithClasses = React.cloneElement(icon, {
    className: 'size-8 text-gray-500',
  });

  return (
    <div className="flex flex-col items-center gap-2 text-center">
      {iconWithClasses}
      <h5 className="text-gray-800 text-lg leading-tight">{title}</h5>
      <p className="max-w-64 text-gray-700 text-sm leading-normal">{description}</p>
      <div className="flex justify-center flex-wrap items-center gap-2 [&>*]:grow">{actions}</div>
    </div>
  );
};

export default EmptyState;
