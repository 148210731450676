import React, {ForwardedRef, forwardRef} from 'react';
import {InputTypes, InputTypesStatic} from '../types';

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  value?: string;
  type?: InputTypes | InputTypesStatic;
}

const InputText = forwardRef((props: InputTextProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    name = '',
    type = 'text',
    placeholder = '',
  } = props;

  return (
    <input
      { ...props }
      autoComplete="off"
      className="erg-input--text"
      placeholder={placeholder}
      id={name}
      name={name}
      type={type}
      ref={ref}
      />
  )
});

export default InputText;
