import React, {useEffect, useRef, useState} from 'react';
import ImageSlider from '../ImageSlider';
import ScrollableContainer from '../../atoms/ScrollableContainer';
import Thumbnail, {ImageProps} from '../../atoms/Thumbnail';
import useImageContainerHeight from './hooks/useImageContainerHeight';

const FullGalleryDialog: React.FC<{images: ImageProps[]; initialIndex?: number}> = ({images, initialIndex = 0}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  useEffect(() => {
    setSelectedIndex(initialIndex);
  }, [initialIndex]);

  const imageContainerRef = useRef<HTMLDivElement>(null);

  const imageContainerHeight = useImageContainerHeight({imageContainerRef});

  return (
    <div className="flex gap-2.5 md:gap-5 bg-white mx-5 my-10 p-2.5 md:p-5 rounded-lg w-full">
      <div className="w-full">
        <div ref={imageContainerRef}>
          <ImageSlider
            aspect="video"
            fit="contain"
            images={images.map((img) => img.src)}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
        <div className="md:hidden block">
          <ImageSlider aspect="video" images={images.map((img) => img.src)} selectedIndex={selectedIndex} />
        </div>
      </div>
      <div className="hidden md:block">
        <ScrollableContainer direction="vertical">
          <div
            className={`flex flex-col p-1 gap-2.5 overflow-y-auto`}
            style={{maxHeight: imageContainerHeight ? imageContainerHeight : 'auto'}}
          >
            {images.map((img, index) => (
              <Thumbnail
                key={index}
                img={img}
                isSelected={selectedIndex === index}
                onThumbnailHover={() => setSelectedIndex(index)}
              />
            ))}
          </div>
        </ScrollableContainer>
      </div>
    </div>
  );
};

export default FullGalleryDialog;
