import {useState, useEffect, useCallback, useMemo} from 'react';
import {SwipeGalleryProps} from '../types';

export const useSwipeGallery = (props: SwipeGalleryProps) => {
  const {images = [], onOpenCloseGallery} = props;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const openGallery = useCallback(
    (i: number): void => {
      setActiveSlideIndex(i);
      setOpen(true);
    },
    [setOpen, setActiveSlideIndex],
  );

  useEffect(() => {
    if (onOpenCloseGallery) {
      onOpenCloseGallery(open);
    }
  }, [open, onOpenCloseGallery]);

  const imagesArray = useMemo(
    () =>
      images.map((elem) => ({
        description: elem.title,
        src: elem.url,
        renderItem: elem.renderItem,
      })),
    [images],
  );

  return {activeSlideIndex, open, setOpen, openGallery, imagesArray};
};
