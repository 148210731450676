import React from 'react';
import ImageContainer from '../ImageContainer';
import {ThumbnailProps} from './types';

const Thumbnail: React.FC<ThumbnailProps> = ({img, isSelected, onThumbnailHover, onClick, extraCount}) => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onThumbnailHover}
      className={`
        relative rounded focus:outline-none transition-all duration-300 ease-in-out
        ${isSelected ? 'ring-4 ring-gray-800/30' : ''}
      `}
    >
      <div
        className={`
        rounded w-16 h-16 overflow-hidden relative transition-all duration-300 ease-in-out
        ${isSelected ? 'ring-1 ring-gray-800' : ''}
      `}
      >
        <ImageContainer src={img.src} altText={img.alt || 'Thumbnail'} aspect="square" fit="cover" />
        {extraCount && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50">
            <span className="font-bold text-white text-lg">+{extraCount}</span>
          </div>
        )}
      </div>
    </button>
  );
};

export default Thumbnail;
