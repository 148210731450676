import React, {useCallback, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import noop from 'lodash/noop';
import {ReactSVG} from 'react-svg';
import iconVideo from '@ergeon/icons/svg/icon-video.svg';
import Spinner from '../Spinner';
import './index.scss';
import {getMediaType} from './utils/getMediaType';
import {ImageLoaderProps} from './types';

const MAX_LOADING_WAIT_TIME_MS = 5000;

const ImageLoader: React.FC<ImageLoaderProps> = ({url, alt, onImageLoaded = noop, style = {}, ...remainingProps}) => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const imgRef = useRef<HTMLImageElement>(null);
  const mediaType = getMediaType(url);

  const setLoadingDone = useCallback(() => {
    setShowLoader(false);
  }, [setShowLoader]);

  useEffect(
    function replaceLoaderWithImageOnMount() {
      const ref = imgRef.current;
      if (ref) {
        if (ref.complete) {
          setLoadingDone();
          return;
        }

        ref.addEventListener('load', setLoadingDone);
        ref.addEventListener('error', setLoadingDone);
        return () => {
          ref.removeEventListener('load', setLoadingDone);
          ref.removeEventListener('error', setLoadingDone);
        };
      }
    },
    [setLoadingDone, setShowLoader],
  );

  useEffect(
    function ensureToHideLoaderAfterDelay() {
      const timeout = setTimeout(setLoadingDone, MAX_LOADING_WAIT_TIME_MS);
      return () => {
        clearTimeout(timeout);
      };
    },
    [setLoadingDone],
  );

  useEffect(
    function callOnImageLoaded() {
      if (showLoader === false) {
        onImageLoaded();
      }
    },
    [showLoader, onImageLoaded],
  );

  return (
    <div className={classnames('image-loader__wrapper', {'is-loading': showLoader})} style={style}>
      {showLoader && mediaType !== 'video' && (
        <div className="image-loader__spinner">
          <Spinner active={true} borderWidth={0.16} color="lightgray" size={64} />
        </div>
      )}
      {mediaType === 'video' ? (
        <div className="image-loader__image" {...remainingProps} data-testid="video-svg">
          <ReactSVG src={iconVideo} />
        </div>
      ) : (
        <img alt={alt} className="image-loader__image" ref={imgRef} src={url} {...remainingProps} />
      )}
    </div>
  );
};

export default ImageLoader;
