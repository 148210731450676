import {useMemo} from 'react';
import classNames from 'classnames';

interface UseDropdownClassesProps {
  isDropdownShown: boolean;
}

export const useDropdownClasses = ({isDropdownShown}: UseDropdownClassesProps) => {
  const dropdownListClasses = useMemo(
    () =>
      classNames('max-w-96 z-10 transition-all absolute opacity-0', {
        'visible opacity-100 translate-y-1': isDropdownShown,
      }),
    [isDropdownShown],
  );

  return dropdownListClasses;
};
