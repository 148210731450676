import React from 'react';
import classNames from 'classnames';
import './index.scss';
import {SpinnerProps} from './types';


const Spinner: React.FC<SpinnerProps> = ({
  active,
  borderWidth = 0.25,
  color = 'white',
  size = 16,
}) => {
  const sizeNum = Number(size);
  const renderLoaderLines = () =>
    [0, 1, 2].map((i) => (
      <div
        key={i}
        style={{
          borderWidth: `${Math.round(sizeNum * borderWidth)}px`,
          width: `${Math.round(sizeNum)}px`,
          height: `${Math.round(sizeNum)}px`,
        }}
      />
    ));

  if (!active) {
    return null;
  }

  const loaderClasses = classNames(color, 'loader');

  return (
    <div
      className="loader-wrapper"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
      }}
      data-testid="spinner"
    >
      <div className={loaderClasses}>{renderLoaderLines()}</div>
    </div>
  );
};

export default Spinner;
