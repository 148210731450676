export const UUID_COOKIE_NAME = 'fences-uuid';
export const isProduction = process.env.NODE_ENV === 'production';

export const FENCE_SLUG = 'fence-replacement';
export const DRIVEWAY_SLUG = 'driveway-installation';
export const products = [
  { slug: FENCE_SLUG, name: 'Fences & Gates' },
  { slug: DRIVEWAY_SLUG, name: 'Driveways & Patios' },
];

// Place here phone number in raw international format
export const PHONE_NUMBER = '+18883743661';

// regexp for phone validation (123) 123-1234
export const PHONE_REGEXP = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

// mask for empty phone number
export const PHONE_MASK = '(___) ___-____';

export const PRIVACY_POLICY_URL = {
  production: 'https://www.ergeon.com/help/202202880/',
  develop: 'https://dev.ergeon.com/help/202202880/',
};

export const TERMS_OF_USE_URL = {
  production: 'https://www.ergeon.com/help/202302992/',
  develop: 'https://dev.ergeon.com/help/202302992/',
};
