import React, {useMemo, useEffect} from 'react';
import classNames from 'classnames';
import Button from '../Button';
import useCopyToClipboard from './useCopyToClipboard';
import {CopyButtonProps} from './types';

const CopyButton = (props: CopyButtonProps) => {
  const {title, successTitle, copyText, iconSuccess, icon, className, ...buttonProps} = props;

  const {onCopy, isCopied, setIsCopied} = useCopyToClipboard(copyText);

  const containerClassName = useMemo(() => classNames(className), [className]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <Button onClick={onCopy} className={containerClassName} {...buttonProps}>
      {isCopied ? !!iconSuccess && iconSuccess : !!icon && icon}
      {isCopied ? successTitle : title}
    </Button>
  );
};

export default CopyButton;
