import {findComponentByTypes} from './utils';

const PLACE_COMPONENT_PROP_POSTAL_CODE = 'postal_code';
const PLACE_COMPONENT_PROP_LOCALITY = 'locality';
const PLACE_COMPONENT_PROP_SUBLOCALITY = 'sublocality';
const PLACE_COMPONENT_PROP_ROUTE = 'route';
const PLACE_COMPONENT_PROP_STREET_NUMBER = 'street_number';
const PLACE_COMPONENT_PROP_ADM_AREA_LEVEL_1 = 'administrative_area_level_1';
const PLACE_COMPONENT_PROP_ADM_AREA_LEVEL_2 = 'administrative_area_level_2';
const PLACE_COMPONENT_PROP_NBRHD = 'neighborhood';

const ADDRESS_PROP_CITY_NAME = 'city_name';
const ADDRESS_PROP_STREET_NAME = 'street_name';
const ADDRESS_PROP_PRIMARY_NUMBER = 'primary_number';
const ADDRESS_PROP_STATE_ABBR = 'state_abbreviation';
const ADDRESS_PROP_ZIPCODE = 'zipcode';
const ADDRESS_PROP_COUNTY = 'county';

export const cleanZip = (zipcode?: string | null): string => {
  if (!zipcode) {
    return '';
  }
  if (zipcode.length > 5) {
    return zipcode.substr(0, 5);
  }
  return zipcode;
};

// eslint-disable-next-line complexity
export const parsePlace = (place: any): any => {
  let parsedPlace: any = null;
  let cleanedZip: string = '';

  if (place && place.address_components && place.geometry) {
    parsedPlace = {};
    for (let i = 0; i < place.address_components.length; i++) {
      const component = place.address_components[i];
      const componentType = component.types[0];
      switch (componentType) {
        case PLACE_COMPONENT_PROP_POSTAL_CODE:
          cleanedZip = cleanZip(component.short_name);
          break;
        case PLACE_COMPONENT_PROP_LOCALITY:
          parsedPlace[ADDRESS_PROP_CITY_NAME] = component.long_name;
          break;
        case PLACE_COMPONENT_PROP_ROUTE:
          parsedPlace[ADDRESS_PROP_STREET_NAME] = component.long_name;
          break;
        case PLACE_COMPONENT_PROP_STREET_NUMBER:
          parsedPlace[ADDRESS_PROP_PRIMARY_NUMBER] = component.short_name;
          break;
        case PLACE_COMPONENT_PROP_ADM_AREA_LEVEL_1:
          parsedPlace[ADDRESS_PROP_STATE_ABBR] = component.short_name;
          break;
        case PLACE_COMPONENT_PROP_ADM_AREA_LEVEL_2:
          parsedPlace[ADDRESS_PROP_COUNTY] = component.long_name;
          break;
      }
    }

    // For addresses like:
    // 368 MacDonough Street, Brooklyn, NY, United States
    // 14 Tuscan Blue, Newport Coast, CA, United States
    if (!parsedPlace[ADDRESS_PROP_CITY_NAME]) {
      const component = findComponentByTypes(place, [PLACE_COMPONENT_PROP_SUBLOCALITY, PLACE_COMPONENT_PROP_NBRHD]);
      if (component) {
        parsedPlace[ADDRESS_PROP_CITY_NAME] = component.long_name;
      }
    }

    if (cleanedZip) {
      parsedPlace[ADDRESS_PROP_ZIPCODE] = cleanedZip;
    }

    parsedPlace['location'] = place.geometry.location;
    parsedPlace['formatted_address'] = place.formatted_address;
    parsedPlace['raw_address'] = place.formatted_address;
    parsedPlace['place_types'] = place.types;
  }

  return parsedPlace;
};

export default {
  parsePlace,
  cleanZip,
};
