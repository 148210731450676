import React, {useEffect, useState, SetStateAction, Dispatch} from 'react';
import DotNavigation from './DotNavigation';
import {ImageFit, ImageAspect} from 'components/atoms/ImageContainer/types';
import ImageContainer from '../../atoms/ImageContainer';
import NavigationControls from './NavigationControls';

export interface ImageSliderProps {
  images?: (string | Promise<string>)[];
  fit?: ImageFit;
  aspect?: ImageAspect;
  selectedIndex?: number;
  setSelectedIndex?: (index: number) => void;
}

const ImageSlider: React.FC<ImageSliderProps> = (
  {
    images = [],
    fit = 'contain',
    aspect = 'square',
    selectedIndex = 0,
    setSelectedIndex,
  }
  ) => {
  const [activeIndex, setActiveIndex] = useState(selectedIndex);

  useEffect(() => {
    setActiveIndex(selectedIndex);
  }, [selectedIndex]);

  const handleIndexChange = (index: number) => {
    setActiveIndex(index);
    if (setSelectedIndex) {
      setSelectedIndex(index);
    }
  };

  const handleSetActiveIndex: Dispatch<SetStateAction<number>> = (value) => {
    if (typeof value === 'function') {
      const updater = value as (prevState: number) => number;
      const newIndex = updater(activeIndex);
      handleIndexChange(newIndex);
    } else {
      handleIndexChange(value);
    }
  };

  const shouldShowNavigation = images.length > 1;

  return (
    <div className="relative w-full h-[fit-content] erg-image-slider group">
      <ImageContainer
        fit={fit}
        aspect={aspect}
        src={images[activeIndex]}
        altText={`Preview ${activeIndex + 1}`}
        hasHover={false}
      />
      {shouldShowNavigation && (
        <>
          <NavigationControls imagesLength={images.length} setActiveIndex={handleSetActiveIndex} />
          <DotNavigation imagesLength={images.length} activeIndex={activeIndex} setActiveIndex={handleIndexChange} />
        </>
      )}
    </div>
  );
};

export default ImageSlider;
