import React from 'react';

import noop from 'lodash/noop';

import ImageLoader from '../../atoms/ImageLoader';
import { ImageCardProps } from './types';

/**
 * Returns a card with image.
 */
const ImageCard = (props: ImageCardProps) => {
  const { caption, url, title, description, price, onImageLoaded = noop, ...divProps } = props;
  return (
    <div {...divProps} className="card padding-20 soft-border shadow__z1">
      {price && <div className="badge__orange">{'$'.repeat(price)}</div>}
      <ImageLoader alt={caption} onImageLoaded={onImageLoaded} url={url}/>
      <div className="additional-header h2 spacing before__is-6 after__is-6">{title}</div>
      <p>{description}</p>
      <p>{caption}</p>
    </div>
  );
};

export default ImageCard;