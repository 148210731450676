import React from 'react';
import ReactSelect, {components} from 'react-select';
import classNames from 'classnames';
import {ChevronDownIcon, XMarkIcon} from '@ergeon/icons/hero/micro';
import './index.scss';

import {SelectProps} from './types';
import {OptionType} from './types';

export const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="size-6" />
      </components.DropdownIndicator>
    )
  );
};

export const ClearIndicator = (props: any) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <XMarkIcon className="size-6" />
      </components.ClearIndicator>
    )
  );
};

export default class Select extends React.Component<SelectProps> {
  static defaultProps = {
    placeholder: 'Select services…',
  };

  render() {
    const {id, options, isDisabled, isMulti, onChange, name, value, placeholder, defaultValue} = this.props;

    const classes = classNames([
      'react-select-container',
      {
        disabled: isDisabled,
      },
    ]);

    return (
      <ReactSelect<OptionType, boolean>
        className={classes}
        classNamePrefix="react-select"
        components={{DropdownIndicator, ClearIndicator}}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={false}
        {...(id ? {id, instanceId: id} : {})}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    );
  }
}
