import React, { ReactNode, useCallback, useState } from 'react';

import Dialog, { DialogContent, DialogHeader } from '../../organisms/UniversalDialog';
import Dropdown, { useDropdown, usePopper } from '../../molecules/UniversalDropdown';
import { AnimatedPopup } from '../../atoms/PopUp';
import { useIsMobile } from '../../utils/useResponsiveBreakpoint';
import type { MainMenuButton, MainMenuInnerButton } from './types';
import useRenderDropdownButtons from './useRenderDropdownButtons';

import './MainMenu.scss';

interface MainMenuToggleProps {
  onClick(): void;
  ref?: React.RefObject<HTMLButtonElement>;
}

export interface MainMenuProps {
  buttons: Array<MainMenuButton | MainMenuInnerButton>;
  renderToggle(props: MainMenuToggleProps, isMobile: boolean): ReactNode;
}

const DesktopMainMenu = ({ buttons, renderToggle }: MainMenuProps) => {
  const isMobile = useIsMobile();

  const { anchorRef, dropdownRef } = usePopper<HTMLButtonElement>();
  const { setVisible, visible } = useDropdown<HTMLButtonElement>({ anchorRef, dropdownRef });

  const onToggle = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  const onHide = useCallback(() => {
    setVisible(false);
  }, []);

  const renderButtons = useRenderDropdownButtons(onHide);

  return (
    <>
      <div className="MainMenu-toggleItem">{renderToggle({ onClick: onToggle, ref: anchorRef }, isMobile)}</div>
      <Dropdown className="MainMenuDropdown" ref={dropdownRef} visible={visible}>
        {renderButtons(buttons)}
      </Dropdown>
    </>
  );
};

const MobileMainMenu = ({ buttons, renderToggle }: MainMenuProps) => {
  const [visible, setVisible] = useState(false);

  const isMobile = useIsMobile();

  const onToggle = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  const onHide = useCallback(() => {
    setVisible(false);
  }, []);

  const renderButtons = useRenderDropdownButtons(onHide);

  return (
    <>
      {renderToggle({ onClick: onToggle }, isMobile)}
      <AnimatedPopup className="MainMenu-popup" isVisible={visible} onChangeVisible={setVisible} transitionMS={300}>
        <Dialog className="MainMenu-dialog" isVisible>
          <DialogHeader {...{ onClose: onToggle }} />
          <DialogContent>{renderButtons(buttons)}</DialogContent>
        </Dialog>
      </AnimatedPopup>
    </>
  );
};

const MainMenu = (props: MainMenuProps) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <MobileMainMenu {...props} />;
  }
  return <DesktopMainMenu {...props} />;
};

export default MainMenu;
