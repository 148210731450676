import React, {useMemo} from 'react';
import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';
import moment from 'moment';

import LeftArrow from '!@svgr/webpack!@ergeon/icons/svg/icon-arrow-left.svg';
import RightArrow from '!@svgr/webpack!@ergeon/icons/svg/icon-arrow-right.svg';

type Locale = {locale: string};

function DatePickerNav(props: ReactDatePickerCustomHeaderProps & Locale) {
  const {date, decreaseMonth, increaseMonth, locale} = props;

  const [prev, next] = useMemo(
    () => [-1, 1].map((diff) => moment(date).locale(locale).add(diff, 'month').format("MMM 'YY")),
    [date, locale],
  );

  return (
    <div className="CalendarNav">
      <button type="button" className="is-left CalendarNav-button" onClick={decreaseMonth}>
        <LeftArrow />
        {prev}
      </button>
      <span>{moment(date).locale(locale).format("MMM 'YY")}</span>
      <button type="button" className="is-right CalendarNav-button" onClick={increaseMonth}>
        {next}
        <RightArrow />
      </button>
    </div>
  );
}

export default DatePickerNav;
