import React from 'react';

import Title from '../../atoms/Title';
import {CameraIcon} from '@ergeon/icons/hero/outline';
import FullGalleryDialog from '../../molecules/FullGalleryDialog';
import {AnimatedPopup} from '../../atoms/PopUp';
import Button from '../../atoms/Button';
import {XMarkIcon} from '@ergeon/icons/hero/micro';
import EmptyState from '../../molecules/EmptyState';
import ScrollableContainer from '../../atoms/ScrollableContainer';
import ImageContainer from '../../atoms/ImageContainer';

import {SwipeGalleryProps} from './types';
import {useSwipeGallery} from './hooks/useSwipeGallery';

const SwipeGallery = (props: SwipeGalleryProps) => {
  const {images = [], titleIcon, title} = props;
  const {activeSlideIndex, open, setOpen, openGallery, imagesArray} = useSwipeGallery(props);

  if (images.length === 0) {
    return <EmptyState title="No images available" icon={<CameraIcon />} />;
  }

  return (
    <div className="flex flex-col gap-2">
      {title && (
        <Title icon={titleIcon}>
          {title} ({images.length})
        </Title>
      )}
      <ScrollableContainer direction="horizontal">
        <div className="flex gap-2 py-2">
          {images.map((item, i) => (
            <button
              className="rounded size-24 transition-all duration-300 ease-in-out cursor-pointer shrink-0"
              key={item.url}
              onClick={() => openGallery(i)}
            >
              <ImageContainer src={item.url} hasHover altText={item.title || 'Thumbnail'} aspect="square" fit="cover" />
            </button>
          ))}
        </div>
      </ScrollableContainer>

      <AnimatedPopup isVisible={open} onChangeVisible={(visible) => setOpen(visible)}>
        <div className="top-0 right-5 absolute">
          <Button
            onClick={() => setOpen(false)}
            flavor="regular"
            taste="boundless"
            className="text-white/50 hover:text-white"
          >
            Close [Esc]
            <XMarkIcon className="size-4" />
          </Button>
        </div>
        <FullGalleryDialog images={imagesArray} initialIndex={activeSlideIndex} />
      </AnimatedPopup>
    </div>
  );
};

export default SwipeGallery;
