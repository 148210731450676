import React, {useState} from 'react';
import classNames from 'classnames';
import {XMarkIcon} from '@ergeon/icons/hero/micro';
import Button from '../../atoms/Button';
import NotificationIcon from './NotificationIcon';

import {NotificationProps} from './types';

const Notification = ({
  children,
  className,
  mode = 'floating',
  onClick,
  onClose,
  type = 'Success',
}: NotificationProps) => {
  const [display, setDisplay] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const close = () => {
    setIsClosing(true); // Trigger the closing animation
    setTimeout(() => setDisplay(false), 300); // Wait for animation to complete
  };

  const floating = mode === 'floating';
  const embed = mode === 'embed';
  const transparent = mode === 'transparent';

  const isError = type === 'Error';
  const isWarning = type === 'Warning';
  const isSuccess = type === 'Success';
  const isInformation = type === 'Information';

  const notificationClasses = classNames(
    'text-black rounded-md text-sm leading-normal flex flex-col gap-1',
    {
      block: display,
      hidden: !display,
      'animate-fade-out': isClosing, // Add fadeOut animation when closing

      // Backgrounds
      'bg-red': transparent,
      'bg-red-100': isError,
      'bg-orange-100': isWarning,
      'bg-green-100': isSuccess,
      'bg-blue-100': isInformation,
      'bg-white': floating,

      // Borders
      'border border-gray-300 shadow-md': floating,
      'border border-red-200': isError && embed,
      'border border-orange-200': isWarning && embed,
      'border border-green-200': isSuccess && embed,
      'border border-blue-200': isInformation && embed,
    },
    className,
  );

  const handleClose = () => {
    close();
    if (onClose) {
      onClose();
    }
  };

  if (!display) return null;

  return (
    <div className={notificationClasses} onClick={onClick}>
      <div className="flex items-start gap-2.5 p-5">
        <div data-testid={`icon-${type}`}>
          <NotificationIcon type={type} />
        </div>

        <div data-testid="notification-value" className="flex flex-col gap-1 w-full">
          {children}
        </div>

        {floating && (
          <div className="max-h-5">
            <Button
              data-testid="close-btn"
              onClick={handleClose}
              isSquare
              size="small"
              taste="boundless"
              flavor="regular"
            >
              <XMarkIcon className="size-4 text-gray-600" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
