// QuoteLineMedia.tsx
import React from "react";
import ImageSlider from "../ImageSlider/ImageSlider";
import { MediaProps } from "./types";

const QuoteLineMedia: React.FC<MediaProps> = ({ mediaData }) => {
  if (!mediaData || mediaData.length === 0) return null;
  const images = mediaData.map((media) => media.url);

  return (
    <div className="
     relative
     min-w-16 w-24 md:w-32
     aspect-square
     ">
      <ImageSlider images={images} fit="contain" aspect="square" />
    </div>
  );
};

export default QuoteLineMedia;
