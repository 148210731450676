import React, {useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

import './index.scss';
import {HighlighterProps} from './types';
/**
 * This component highlights every `searchText` appearance in the `children`.
 * You can use this function like:
 * <Higlighter searchText="really good day">{This is really good day!!!}</Highlighter>
 */
const Highlighter = ({children, searchText, ...divAttr}: HighlighterProps): JSX.Element => {
  if (isEmpty(searchText)) return <div className="highlighter">{children}</div>;
  // Prepend all non-alpha-numeric occurancies with `\\`
  const searchTextSafe = useMemo(() => searchText.replace(/[^\w\s]/gi, '\\$&'), [searchText]);
  const regexp = useMemo(() => new RegExp(`(${searchTextSafe})`, 'gi'), [searchText]);
  const result = useMemo(
    () =>
      children.split(regexp).map((part, index) => {
        if (regexp.test(part))
          return (
            <span className="marked-word" key={index}>
              {part}
            </span>
          );
        return part;
      }),
    [children, searchText],
  );

  return (
    <div {...divAttr} className="highlighter">
      {result}
    </div>
  );
};

export default Highlighter;
