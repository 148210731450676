// InputController.tsx
import React, { forwardRef, useRef } from 'react'
import {defaultProperties} from './defaultProperties';
import useInputClasses from './hooks/useInputClasses'
import usePreventWheelEvent from './hooks/usePreventWheelEvent';
import InputUI from './components/InputUI'
import InputFactory from './components/InputFactory'
import {InputProps} from './types'
import useInputHandlers from './hooks/useInputHandlers'

import './index.scss'
import {getUniqueId} from './utils';

const InputController = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {

  const propsWithDefaults = {
    ...defaultProperties,
    name: getUniqueId('erg-input'), // This is a unique ID for the input element and cannot be static
    ...props,
    // Override "isDisabled" with "disabled" if "isDisabled" is not provided,
    // since some of the projects send "disabled" instead of "isDisabled"
    isDisabled: (props.isDisabled === undefined)? props.disabled : props.isDisabled,
  }

  const {
    className,
    isDisabled,
    label,
    name,
    rightIcon,
    size,
    validationMessage,
    value,
    onFocus,
    onBlur,
    onChange,
    onKeyUp,
    isValid,
    min,
    max,
    type,
    maxLength,
  } = propsWithDefaults;

  const containerRef = useRef<HTMLDivElement>(null)

  const { isFocus, getCheckedValue, handleChange, handleKeyUp, handleBlur, handleFocus } = useInputHandlers({
    type,
    min,
    max,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    maxLength,
    name,
    value,
  })

  usePreventWheelEvent({containerRef, type});

  const checkedValue = getCheckedValue(value)

  const inputComponent = (
    <InputFactory
      {...propsWithDefaults}
      value={checkedValue}
      handleOnFocus={handleFocus}
      handleOnBlur={handleBlur}
      handleKeyUp={handleKeyUp}
      handleInputChange={handleChange}
      ref={ref}
    />
  )

  const { containerClasses } = useInputClasses({
    sizeClass: size,
    isDisabled,
    hasLabel: Boolean(label),
    isValid,
    className,
    hasValue: Boolean(checkedValue),
    isFocus,
  })

  return (
    <InputUI
      containerClasses={containerClasses}
      containerRef={containerRef}
      inputComponent={inputComponent}
      rightIcon={rightIcon}
      name={name}
      label={label}
      isValid={isValid}
      hasValue={ Boolean(checkedValue)}
      validationMessage={validationMessage}
    />
  )
})

export default InputController
