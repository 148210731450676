import React from 'react';
import MessageItem from './MessageItem';
import EmptyState from '../../molecules/EmptyState';
import {MessageItemProps} from './types';
import {ChatBubbleLeftRightIcon} from '@ergeon/icons/hero/outline';

interface MessageFeedProps {
  messages: MessageItemProps[];
}

const MessageFeed: React.FC<MessageFeedProps> = ({messages}) => {
  const renderMessages = () =>
    messages.length === 0 ? (
      <EmptyState
        icon={<ChatBubbleLeftRightIcon />}
        title="No messages to show"
        description="We will show your message history here"
      />
    ) : (
      messages.map((message, index) => <MessageItem key={index} {...message} />)
    );

  return <div className="flex flex-col gap-4 erg-message-feed">{renderMessages()}</div>;
};

export default MessageFeed;
