import React from 'react';

import classNames from 'classnames';

import './index.scss';

import {FormFieldProps} from './types';

const FormField = (props: FormFieldProps) => {
  const {children, className, labelClassName, errorClassName, error, label, name, showError = true, ...divAttr} = props;

  return (
    <div {...divAttr} className={classNames('erg-form-field', className)}>
      {!!label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}
      {children}
      {showError && !!error && <p className={errorClassName}>{error}</p>}
    </div>
  );
};

export default FormField;
