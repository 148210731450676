import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import classNames from 'classnames';
import {getWindow} from 'ssr-window';

import './index.scss';
import {OptimizedImageProps} from './types';
import {ImgRef} from './types';

const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0px  0px 100px 0px',
  threshold: 0,
};

const OptimizedImage = ({
  src,
  alt,
  isBgImg = false,
  isLazy = true,
  isPlaceholder = false,
  wrapperClassName = '',
  ...props
}: OptimizedImageProps) => {
  const shouldLazyLoad = useMemo(() => {
    const window = getWindow();
    return 'IntersectionObserver' in window && isLazy;
  }, [isLazy]);

  const imgRef = useRef<ImgRef>(null);
  const [imgSrc, setImgSrc] = useState(shouldLazyLoad ? '' : src);
  const [loading, setLoading] = useState(isPlaceholder);

  const observerCallback = useCallback(
    (entries) => {
      if (entries[0].isIntersecting) {
        setImgSrc(src);
      }
    },
    [src],
  );

  useEffect(() => {
    if (!shouldLazyLoad) return;

    const observer = new IntersectionObserver(observerCallback, OBSERVER_OPTIONS);

    if (imgRef.current) observer.observe(imgRef.current);

    return () => {
      if (imgRef.current) observer.unobserve(imgRef.current);
    };
  }, [imgRef, shouldLazyLoad, observerCallback]);

  const imgSizes = useMemo(() => {
    return {
      width: props?.width || imgRef.current?.width || '100%',
      height: props?.height || imgRef.current?.height || '100%',
    };
  }, [props, imgRef.current]);

  const imgClassName = useMemo(() => {
    return classNames('optimized-img', wrapperClassName, {
      'is-loading': loading,
    });
  }, [loading, wrapperClassName]);

  const bgImgClassName = useMemo(() => {
    return classNames('bg-img', {
      'is-placeholder': isPlaceholder,
    });
  }, [isPlaceholder]);

  const onStopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  if (isBgImg) {
    return (
      <div
        {...props}
        aria-label={props['aria-label'] || alt}
        className={bgImgClassName}
        ref={imgRef}
        style={{
          backgroundImage: `url(${imgSrc || src})`,
          ...props.style,
        }}
      />
    );
  }

  return (
    <div className={imgClassName}>
      <img
        {...props}
        alt={alt}
        data-animated={isPlaceholder}
        data-optimized
        height={imgSizes?.height}
        loading={shouldLazyLoad ? 'lazy' : 'eager'}
        onError={onStopLoading}
        onLoad={onStopLoading}
        ref={imgRef}
        src={imgSrc || src}
        width={imgSizes?.width}
      />
    </div>
  );
};

export default OptimizedImage;
