import React from 'react';
import {CheckMarkProps} from './types';

import './CheckMark.scss';

const CheckMark: React.FC<CheckMarkProps> = ({className, ...rest}) => {
  return (
    <div className={`checkMark ${className || ''}`} {...rest}>
      <div className="checkMark-line checkMark-line-long" />
      <div className="checkMark-line checkMark-line-tip" />
      <div className="checkMark-ring" />
      <div className="checkMark-hide-corners" />
    </div>
  );
};

export default CheckMark;
