import React, {useMemo} from 'react';
import classNames from 'classnames';
import ReactQRCode from 'react-qr-code';
import {QRCodeProps} from './types';

const QRCode = (props: QRCodeProps) => {
  const {value, className, ...qrCodeProps} = props;

  const containerClassName = useMemo(() => classNames(className), [className]);

  return (
    <div className={`${containerClassName} dark:invert`}>
      <ReactQRCode {...qrCodeProps} value={value} />
    </div>
  );
};

export default QRCode;
