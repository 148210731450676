import React, {useState} from 'react';
import Button from '../../atoms/Button';
import ImageContainer from '../../atoms/ImageContainer';
import {AnimatedPopup} from '../../atoms/PopUp';
import FullGalleryDialog from '../../molecules/FullGalleryDialog';
import Thumbnails from './Thumbnails';
import {XMarkIcon} from '@ergeon/icons/hero/micro';
import {ThumbnailGalleryProps} from './types';

const ThumbnailGallery: React.FC<ThumbnailGalleryProps> = ({
  images,
  thumbnailPosition = 'bottom',
  maxThumbnails = 4,
  initialIndex = 0,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [showPopup, setShowPopup] = useState(false);

  const handleThumbnailHover = (index: number) => {
    setSelectedIndex(index);
  };

  const isHorizontal = thumbnailPosition === 'top' || thumbnailPosition === 'bottom';
  const galleryClass = isHorizontal ? 'flex flex-col items-center space-y-4' : 'flex items-center space-x-4';

  const mainImageComponent = (
    <div
      className="relative rounded hover:ring-4 hover:ring-gray-800/30 w-96 transition-all duration-300 ease-in-out cursor-pointer"
      onClick={() => setShowPopup(true)}
    >
      <ImageContainer
        hasHover
        src={images[selectedIndex].src}
        altText={images[selectedIndex].alt || 'Product image'}
        aspect="video"
        fit="contain"
      />
    </div>
  );

  const thumbnailsComponent = (
    <Thumbnails
      images={images}
      selectedIndex={selectedIndex}
      onThumbnailHover={handleThumbnailHover}
      thumbnailPosition={thumbnailPosition}
      maxThumbnails={maxThumbnails}
      onExtraClick={() => setShowPopup(true)}
    />
  );

  const content =
    thumbnailPosition === 'top' || thumbnailPosition === 'left' ? (
      <>
        {thumbnailsComponent}
        {mainImageComponent}
      </>
    ) : (
      <>
        {mainImageComponent}
        {thumbnailsComponent}
      </>
    );

  return (
    <div className="relative">
      <div className={galleryClass}>{content}</div>
      <AnimatedPopup isVisible={showPopup} onChangeVisible={() => setShowPopup(false)}>
        <div className="top-0 right-5 absolute">
          <Button
            onClick={() => setShowPopup(false)}
            flavor="regular"
            taste="boundless"
            className="text-white/50 hover:text-white"
          >
            Close [Esc]
            <XMarkIcon className="size-4" />
          </Button>
        </div>
        <FullGalleryDialog images={images} initialIndex={selectedIndex} />
      </AnimatedPopup>
    </div>
  );
};

export default ThumbnailGallery;
