import React, {forwardRef, useCallback, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

import PopUp from '../PopUp';
import {PopupProps} from '../types';

import './AnimatedPopup.scss';

export const ANIMATED_POPUP_DEFAULT_TRANSITION = 200;

interface AnimatedPopup extends PopupProps {
  transitionMS?: number;
}

const AnimatedPopup = forwardRef<HTMLDivElement, AnimatedPopup>((props, ref) => {
  const {isVisible, transitionMS = ANIMATED_POPUP_DEFAULT_TRANSITION, ...popupProps} = props;

  const [innerVisible, setInnerVisible] = useState(isVisible);

  const onClose = useCallback(() => {
    setInnerVisible(false);
  }, []);

  useEffect(
    function syncPopupShow() {
      if (isVisible) {
        setInnerVisible(true);
      }
    },
    [isVisible],
  );

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      classNames="AnimatedPopup"
      in={isVisible}
      nodeRef={ref}
      onExited={onClose}
      timeout={isVisible ? 0 : transitionMS}
    >
      <PopUp {...popupProps} isVisible={isVisible || innerVisible} ref={ref} />
    </CSSTransition>
  );
});

export default AnimatedPopup;
