import React, { KeyboardEvent, InputHTMLAttributes } from 'react';

type CustomHTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange' | 'onKeyUp'>;

export interface InputProps extends CustomHTMLInputProps {
  className?: string;
  isDisabled?: boolean;
  label?: string;
  mask?: string | (string | RegExp)[];
  isMultiline?: boolean;
  name?: string;
  onFocus?: (e: OnFocusType) => void;
  onBlur?: (e: OnFocusType) => void;
  onChange?: (e: OnChangeType, name?: string, value?: string) => void;
  onKeyUp?: (e: React.KeyboardEvent, name?: string, value?: string) => void;
  placeholder?: string;
  rightIcon?: JSX.Element;
  size?: InputSize | InputSizesStatic;
  type?: InputTypes | InputTypesStatic;
  isValid?: boolean;
  validationMessage?: string;
  value?: string;
  min?: number;
  max?: number;
  multilineRows?: number;
  multilineCols?: number;
}

export type OnChangeType = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
export type OnFocusType = React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>;

export interface InputHandlers {
  handleOnFocus: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleOnBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleKeyUp: (e: KeyboardEvent) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export type InputTypesStatic = 'email' | 'password' | 'text' | 'tel' | 'number' | 'amount';
export type InputSizesStatic = 'small' | 'medium';

export enum InputTypes {
  Email = 'email',
  Password = 'password',
  Text = 'text',
  Tel = 'tel',
  Number = 'number',
  Amount = 'amount'
}

export enum InputSize {
  Small = 'small',
  Medium = 'medium'
}
