import React from 'react';
import {TitleType} from './types';

const Title: React.FC<TitleType> = ({icon, className, children, ...divAttr}) => {
  return (
    <span {...divAttr} className={`flex items-center gap-1 text-gray-700 dark:text-white title ${className || ''}`}>
      {icon && (typeof icon === 'string' ? <img className="size-4 title-icon" alt="" src={icon} /> : <>{icon}</>)}
      {children}
    </span>
  );
};

export default Title;
