import React, {forwardRef} from 'react';

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement | HTMLInputElement> {
  className?: string;
  disabled?: boolean;
  name?: string;
  rows?: number
  cols?: number
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    name = '',
    cols = 50,
    rows = 2,
    placeholder = '',
  } = props;

  return (
    <textarea
      {...props}
      cols={cols}
      rows={rows}
      className='erg-input--text'
      id={name}
      name={name}
      ref={ref}
      placeholder={placeholder}
    />
  );
});

export default TextArea;
